import React, { useState, useContext } from "react";
import { NavLink, useHistory } from "react-router-dom";
import UserContext from "context/UserContext";
import { logInUser } from "api/Queries";
import ErrorAlert from "modules/common/components/ErrorAlert";
import PagePadding from "modules/navbar/components/PagePadding";

// Login component
const Login = () => {
  //useStates for form
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [loginError, setLoginError] = useState("");

  // initiate history
  const history = useHistory();

  // bring getUser function in from context (gets logged in user from cookie)
  const { getUser } = useContext(UserContext);

  // function to handle login click
  async function login(e) {
    e.preventDefault();
    const loginFormBody = { email: loginEmail, password: loginPassword };
    try {
      await logInUser(loginFormBody);
      await getUser();
      history.goBack();
    } catch (error) {
      setLoginError(error.response.data.errorMessage);
    }
  }

  return (
    <>
      <PagePadding />
      <div className="flex flex-grow items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Log in to Travel Budgy
            </h2>
          </div>
          <form className="mt-8 space-y-6 relative" onSubmit={login}>
            <input type="hidden" name="remember" value="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label forhtml="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  onChange={(e) => {
                    setLoginEmail(e.target.value);
                  }}
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                />
              </div>
              <div>
                <label forhtml="password" className="sr-only">
                  Password
                </label>
                <input
                  onChange={(e) => setLoginPassword(e.target.value)}
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                />
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Log in
              </button>
            </div>
            {loginError && <ErrorAlert errorMessage={loginError} />}
          </form>
          <div className="text-center text-theme-red-400 hover:text-theme-red-500">
            <NavLink to="/forgotPassword">Forgot your Password?</NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
