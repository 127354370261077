import React, { useState, useEffect } from "react";
import { createTripForUser } from "api/Queries";
import { useMutation } from "react-query";
import { useHistory } from "react-router";
import FormModal from "modules/common/components/FormModal";
import CreateTripForm from "./CreateTripForm";

const CreateTripModal = ({ setShowCreateTripModal, showCreateTripModal }) => {
  const [tripTitle, setTripTitle] = useState("");
  const [from, setFrom] = useState(undefined);
  const [to, setTo] = useState(undefined);
  const history = useHistory();

  const submitTripMutation = useMutation(createTripForUser, {
    onSuccess: (response) => {
      const id = response.data._id
      setShowCreateTripModal(false);
      history.push("trips/" + id + "/edit")
    },
  });

  function submitTrip() {
    submitTripMutation.mutate({
      title: tripTitle,
      startDate: from,
      endDate: to,
    });
  }

  useEffect(() => {
    if (showCreateTripModal) return;
    setTo(undefined);
    setFrom(undefined);
    setTripTitle("");
  }, [showCreateTripModal]);

  return (
    <FormModal
      onSubmit={submitTrip}
      showModal={showCreateTripModal}
      setShowModal={setShowCreateTripModal}
      disableSubmit={submitTripMutation.isLoading}
      errorMessage={submitTripMutation?.error?.response?.data?.errorMessage}
      modalTitle="Create a new Trip"
      formComponent={
        <CreateTripForm
          to={to}
          setTo={setTo}
          from={from}
          setFrom={setFrom}
          tripTitle={tripTitle}
          setTripTitle={setTripTitle}
        />
      }
    />
  );
};

export default CreateTripModal;
