import React, { useState, useRef, useReducer, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import { postEvent, uploadImage } from "api/Queries";
import ClipLoader from "react-spinners/ClipLoader";
import MDEditor from "@uiw/react-md-editor";
import PointInTime from "modules/eventcreate/components/PointInTime";
import dropdownEventTypes from "modules/eventcreate/helpers/DropdownData";
import dropdownEventSubTypes from "modules/eventcreate/helpers/DropdownData";

// Reducer Actions
export const ACTIONS = {
  UPDATE_EVENT_INPUT_FIELD: "update-event-form-field",
  UPDATE_EVENT_BOOLEAN_FIELD: "update-event-boolean-field",
  SET_INIT_POINTS_IN_TIME: "set-init-points-in-time",
  ADD_POINTS_IN_TIME: "add-points-in-time",
  UPDATE_POINT_IN_TIME: "update-point-in-time",
};

// Events component
const EventCreate = () => {
  // Reducer for event form
  const [eventForm, eventFormDispatch] = useReducer(eventFormReducer, {
    public: false,
  });

  function eventFormReducer(eventForm, action) {
    switch (action.type) {
      case ACTIONS.UPDATE_EVENT_INPUT_FIELD:
        return { ...eventForm, [action.payload.attr]: action.payload.value };
      case ACTIONS.UPDATE_EVENT_BOOLEAN_FIELD:
        return {
          ...eventForm,
          [action.payload.attr]: !eventForm[action.payload.attr],
        };
      case ACTIONS.SET_INIT_POINTS_IN_TIME:
        return {
          ...eventForm,
          pointsInTime: setInitPointsInTime(
            dropdownEventSubTypes.dropdownEventSubTypes[eventForm.type]
              .pitTitles,
            eventForm.subType
          ),
        };
      case ACTIONS.UPDATE_POINT_IN_TIME:
        return {
          ...eventForm,
          pointsInTime: eventForm.pointsInTime.map((pointInTime) => {
            if (pointInTime.id === action.payload.id)
              return {
                ...pointInTime,
                [action.payload.attr]: action.payload.value,
              };
            return pointInTime;
          }),
        };

      default:
        return eventForm;
    }
  }

  function setInitPointsInTime(subTitles, subType) {
    if (subTitles.length > 0) {
      return subTitles.map((subTitle) => ({
        id: subTitle + Date.now(),
        subTitle: subTitle,
      }));
    } else {
      return [{ id: subType + Date.now(), subTitle: subType }];
    }
  }

  // state
  const [eventCreateImage, setEventCreateImage] = useState();
  const [eventCreateError, setEventCreateError] = useState();
  const [eventCreateAdditionalInfo, setEventCreateAdditionalInfo] = useState(
    true
  );

  // initiate history
  const history = useHistory();

  // useRefs
  const inputFile = useRef(null);

  // Mutations
  const eventMutation = useMutation(postEvent, {
    onError: async (error) => {
      console.log(error.response.data.errorMessage);
      setEventCreateError(error.response.data.errorMessage);
    },
    onSuccess: async () => {
      history.goBack();
    },
  });

  const imageMutation = useMutation(uploadImage, {
    onError: async (error) => {
      console.log(error);

      setEventCreateError(error.response.data.errorMessage);
    },
    onSuccess: async (data) => {
      setEventCreateImage(data.image_url);
    },
  });

  // function to run eventFormDispatch

  const handleEventUpdate = (attr, value) => {
    eventFormDispatch({
      type: ACTIONS.UPDATE_EVENT_INPUT_FIELD,
      payload: { attr: attr, value: value },
    });
  };

  // useEffect to set Point in Times based on Type
  useEffect(() => {
    if (eventForm.subType)
      eventFormDispatch({
        type: ACTIONS.SET_INIT_POINTS_IN_TIME,
      });
  }, [eventForm.subType]);

  // Function to handle create event click
  async function createEvent(e) {
    e.preventDefault();
    const eventCreateBody = {
      title: eventForm.title,
      type: eventForm.type,
      subType: eventForm.subType,
      image: eventCreateImage,
      description: eventForm.description,
      quality: eventForm.quality,
      cost: eventForm.cost,
      public: eventForm.public,
      currency: eventForm.currency,
      internetCarrier: eventForm.internetCarrier,
      internetDownload: eventForm.internetDownload,
      internetUpload: eventForm.internetUpload,
      pointsInTime: eventForm.pointsInTime,
    };

    // On Create Event fire mutation to createEvent
    try {
      eventMutation.mutate(eventCreateBody);
    } catch (error) {
      console.log("Event Failed to create");
    }
  }

  // Function for creating image
  async function submitImage(e) {
    e.preventDefault();
    const newImage = e.target.files[0];
    const bodyFormData = new FormData();

    bodyFormData.append("image", newImage);
    try {
      imageMutation.mutate(bodyFormData);
    } catch (error) {
      console.log(error);
      setEventCreateError(error.response.data.errorMessage);
    }
  }

  // function to handle button click so native image file loader opens
  const onButtonClick = () => {
    inputFile.current.click();
  };

  return (
    <div className="pb-24 ">
      <div className="container mx-auto px-8 sm:px-8 lg:px-72 pt-8 space-y-6">
        <div className="space-y-6 grid grid-cols-4 ">
          <div className="col-span-4 grid grid-cols-4 gap-4">
            {eventCreateError && (
              <div className="col-span-4">{eventCreateError}</div>
            )}

            <div className="col-span-2">
              <label
                htmlFor="eventCreateType"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Type
              </label>

              <div className="mt-1">
                <select
                  defaultValue={"default"}
                  id="eventCreateType"
                  name="eventCreateType"
                  onChange={(e) =>
                    eventFormDispatch({
                      type: ACTIONS.UPDATE_EVENT_INPUT_FIELD,
                      payload: { attr: "type", value: e.target.value },
                    })
                  }
                  className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option disabled value="default">
                    -- select --
                  </option>
                  {dropdownEventTypes &&
                    dropdownEventTypes.dropdownEventTypes.map((eventType) => (
                      <option key={eventType}>{eventType}</option>
                    ))}
                </select>
              </div>
            </div>

            <div className="col-span-2">
              <label
                htmlFor="eventCreateSubType"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Sub Type
              </label>
              <div className="mt-1">
                <select
                  defaultValue={"default"}
                  id="eventCreateSubType"
                  name="eventCreateSubType"
                  onChange={(e) => handleEventUpdate("subType", e.target.value)}
                  className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                >
                  <option value="default">-- select --</option>
                  {dropdownEventSubTypes &&
                    eventForm.type !== "" &&
                    eventForm.type &&
                    dropdownEventSubTypes.dropdownEventSubTypes[
                      eventForm.type
                    ].subTypes.map((eventSubType) => (
                      <option key={eventSubType}>{eventSubType}</option>
                    ))}
                </select>
              </div>
            </div>
          </div>

          <div className="col-span-4">
            <label
              htmlFor="eventCreateTitle"
              className="block text-sm font-medium text-gray-700"
            >
              Title
            </label>
            <input
              id="eventCreateTitle"
              name="eventCreateTitle"
              autoComplete="off"
              onChange={(e) => handleEventUpdate("title", e.target.value)}
              className="mt-1 block w-full border border-gray-300 max-w-md rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            ></input>
          </div>

          <div className="col-span-4">
            <label
              htmlFor="eventCreateDescription"
              className="block mb-1 text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Description
            </label>
            <MDEditor
              height={200}
              value={eventForm.description ? eventForm.description : ""}
              preview="edit"
              visiableDragbar={false}
              onChange={(value) => handleEventUpdate("description", value)}
              className="overflow-hidden"
            />
          </div>

          <input
            id="imageInput"
            type="file"
            accept="image/*"
            ref={inputFile}
            onChange={(e) => submitImage(e)}
            style={{ display: "none" }}
          />
          <div className="col-span-4 grid grid-cols-4 gap-4">
            <button
              type="button"
              id="openImageUpload"
              onClick={onButtonClick}
              className="max-w-xs col-span-2 inline-flex justify-center items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Upload Image
            </button>
            {imageMutation.isLoading && (
              <div className="col-span-1 flex items-center">
                <ClipLoader color="#4450b5" />
              </div>
            )}

            {imageMutation.isSuccess && (
              <div className="col-span-1 flex items-center">
                {eventCreateImage && (
                  <img
                    src={eventCreateImage.toString()}
                    className="h-10"
                    alt="thing"
                  ></img>
                )}
              </div>
            )}
          </div>

          <div className="flex items-center">
            <label htmlFor="eventCreatePublic">
              <span className="block text-sm font-medium text-gray-700 mr-3">
                Public
              </span>
            </label>
            <button
              id="eventCreatePublic"
              name="eventCreatePublic"
              type="button"
              aria-pressed="false"
              onClick={(e) =>
                eventFormDispatch({
                  type: ACTIONS.UPDATE_EVENT_BOOLEAN_FIELD,
                  payload: { attr: "public", value: e.target.value },
                })
              }
              className={
                (eventForm.public ? "bg-indigo-600" : "bg-gray-200") +
                " relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              }
            >
              <span className="sr-only">Use setting</span>

              <span
                aria-hidden="true"
                className={
                  (eventForm.public ? "translate-x-5" : "translate-x-0") +
                  " pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                }
              />
            </button>
          </div>
          <div className="col-span-4">
            <button
              className=" text-indigo-600 flex justify-start focus:outline-none active:outline-none disabled:opacity-50"
              onClick={() =>
                setEventCreateAdditionalInfo(!eventCreateAdditionalInfo)
              }
              href="#"
              disabled={eventForm.type ? false : true}
            >
              {eventCreateAdditionalInfo
                ? "Hide Additional Fields"
                : "Show Additional Fields"}
            </button>
          </div>
          {eventCreateAdditionalInfo && (
            <>
              <h3 className="col-span-4 text-2xl">Time & Location</h3>
              {eventForm.pointsInTime &&
                eventForm.pointsInTime.map((pointInTime) => (
                  <PointInTime
                    key={pointInTime.id}
                    pointInTime={pointInTime}
                    dispatch={eventFormDispatch}
                  />
                ))}

              <h3 className="col-span-4 text-2xl">Pricing</h3>
              <div className="col-span-4">
                <label
                  htmlFor="eventCreateQuality"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Quality
                </label>
                <div className="mt-1">
                  <select
                    defaultValue={"default"}
                    id="eventCreateQuality"
                    name="eventCreateQuality"
                    onChange={(e) =>
                      handleEventUpdate("quality", e.target.value)
                    }
                    className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option disabled value="default">
                      -- select --
                    </option>
                    <option>Budget</option>
                    <option>Standard</option>
                    <option>Luxury</option>
                  </select>
                </div>
              </div>

              <div className="col-span-4 grid grid-cols-4 gap-4">
                <div className="col-span-2">
                  <label
                    htmlFor="eventCreateCost"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Cost
                  </label>
                  <input
                    id="eventCreateCost"
                    name="eventCreateCost"
                    type="number"
                    autoComplete="off"
                    onChange={(e) =>
                      handleEventUpdate("cost", parseInt(e.target.value))
                    }
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  ></input>
                </div>

                <div className="col-span-2">
                  <label
                    htmlFor="eventCreateCurrency"
                    className="block text-sm font-medium text-gray-700 sm:mt-px "
                  >
                    Currency
                  </label>
                  <div className="mt-1">
                    <select
                      defaultValue={"default"}
                      id="eventCreateCurrency"
                      name="eventCreateCurrency"
                      onChange={(e) =>
                        handleEventUpdate("currency", e.target.value)
                      }
                      className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    >
                      <option disabled value="default">
                        -- select --
                      </option>
                      <option>USD</option>
                    </select>
                  </div>
                </div>
              </div>

              <h3 className="col-span-4 text-2xl">Internet</h3>
              <div className="col-span-4">
                <label
                  htmlFor="eventCreateInternetCarrier"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Source/Carrier
                </label>
                <div className="mt-1">
                  <select
                    defaultValue={"default"}
                    id="eventCreateInternetCarrier"
                    name="eventCreateInternetCarrier"
                    onChange={(e) =>
                      handleEventUpdate("internetCarrier", e.target.value)
                    }
                    className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option disabled value="default">
                      -- select --
                    </option>
                    <option>Verizon Mobile</option>
                    <option>Tmobile</option>
                    <option>Wifi</option>
                  </select>
                </div>
              </div>

              <div className="col-span-4 grid grid-cols-4 gap-4 pb-8">
                <div className="col-span-2">
                  <label
                    htmlFor="eventCreateInternetDownload"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Download Speed
                  </label>
                  <input
                    id="eventCreateInternetDownload"
                    name="eventCreateInternetDownload"
                    type="number"
                    autoComplete="off"
                    onChange={(e) =>
                      handleEventUpdate(
                        "internetDownload",
                        parseInt(e.target.value)
                      )
                    }
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  ></input>
                </div>

                <div className="col-span-2">
                  <label
                    htmlFor="eventCreateInternetUpload"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Upload Speed
                  </label>
                  <input
                    id="eventCreateInternetUpload"
                    name="eventCreateInternetUpload"
                    type="number"
                    autoComplete="off"
                    onChange={(e) =>
                      handleEventUpdate(
                        "internetUpload",
                        parseInt(e.target.value)
                      )
                    }
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  ></input>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div>
        <button
          type="submit"
          disabled={imageMutation.isLoading || !eventForm.title ? true : false}
          onClick={createEvent}
          className={
            (imageMutation.isLoading ? "bg-indigo-300" : "bg-indigo-600") +
            " fixed bottom-0 border border-transparent h-20 shadow-sm w-full inline-flex justify-center items-center text-lg font-medium text-white hover:bg-indigo-700 focus:outline-none active:outline-none disabled:opacity-50"
          }
        >
          Create Event
        </button>
      </div>
    </div>
  );
};

export default EventCreate;
