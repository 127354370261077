import React, { useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import EventSubTypeBubble from "./EventSubTypeBubble";
import Icon from "modules/common/icons";
import { eventSubTypeInfo } from "modules/common/dicts/eventSubTypeInfo";

const EventTypeBubble = ({
  isOpen,
  type,
  icon,
  typeChosen,
  setTypeChosen,
  setIsOpen,
}) => {
  const eventSubTypes = Object.keys(eventSubTypeInfo[type].subTypes).map(
    (key) => {
      return { type: key, icon: eventSubTypeInfo[type].subTypes[key].icon };
    }
  );

  const bubbleVariants = {
    open: {
      opacity: 1,
      y: -60,
    },
    close: {
      opacity: 0,
      y: 12,
      transition: { type: "spring", stiffness: 400, damping: 40 },
    },
  };
  const containerVariants = {
    open: {
      opacity: 1,
      x: -30,
    },
    close: {
      opacity: 0,
      x: 50,

      transition: {
        duration: 0.15,
      },
    },
  };

  useEffect(() => {
    if (isOpen) {
      setTypeChosen("");
    }
  }, [isOpen, setTypeChosen]);

  const eventTypeButtonVariants = {
    initial: { color: "#ffffff" },
    animate: { color: "#ff3863", backgroundColor: "#801c32", scale: 1.1 },
  };

  return (
    <>
      <motion.li variants={bubbleVariants}>
        <AnimatePresence>
          {typeChosen === type && (
            <motion.ul
              className={
                (eventSubTypes.length > 3 && "-top-12") +
                " fixed right-11 z-20 grid grid-cols-3 w-56 gap-3"
              }
              style={{ direction: "rtl" }}
              variants={containerVariants}
              initial="close"
              animate="open"
              exit="close"
            >
              {eventSubTypes.map((eventSubType, index) => (
                <EventSubTypeBubble
                  icon={eventSubType.icon}
                  index={index}
                  subType={eventSubType.type}
                  type={type}
                  key={eventSubType.type}
                  setIsOpen={setIsOpen}
                ></EventSubTypeBubble>
              ))}
            </motion.ul>
          )}
        </AnimatePresence>
        <motion.button
          onClick={
            typeChosen === type
              ? (e) => {
                  e.stopPropagation();
                  setTypeChosen("");
                }
              : (e) => {
                  e.stopPropagation();
                  setTypeChosen(type);
                }
          }
          className="bg-theme-red-300 h-14 w-14 mb-4 rounded-full flex justify-center items-center outline-none z-40 focus:outline-none focus:shadow-none"
          variants={eventTypeButtonVariants}
          initial="initial"
          animate={typeChosen === type ? "animate" : "initial"}
        >
          <Icon className="h-11 w-11" icon={icon} />
        </motion.button>
      </motion.li>
    </>
  );
};

export default EventTypeBubble;
