import React, { useState, useEffect } from "react";
import CostCurrencyField from "modules/common/components/fields/CostCurrencyField";
import SelectField from "modules/common/components/fields/SelectField";

const PricingSection = ({ handleEventUpdate, eventForm }) => {
  const [cost, setCost] = useState();
  const [currency, setCurrency] = useState();
  const [quality, setQuality] = useState();

  useEffect(() => {
    handleEventUpdate("currency", currency);
  }, [currency, handleEventUpdate]);

  useEffect(() => {
    handleEventUpdate("cost", cost);
  }, [cost, handleEventUpdate]);

  useEffect(() => {
    handleEventUpdate("quality", quality);
  }, [quality, handleEventUpdate]);

  return (
    <>
      <h3 className="col-span-4 text-2xl">Pricing</h3>

      <SelectField
        title="Quality"
        uniqueKey="quality"
        selected={eventForm.quality ? eventForm.quality : quality}
        setSelected={setQuality}
        options={["Budget", "Standard", "Luxury"]}
        className="col-span-4"
      />

      <CostCurrencyField
        cost={eventForm.cost ? eventForm.cost : cost}
        uniqueKey="cost"
        setCost={setCost}
        currency={currency}
        setCurrency={setCurrency}
        className="col-span-4"
      />
    </>
  );
};

export default PricingSection;
