import React, { useState } from "react";
import { resetPassword } from "api/Queries";
import { useHistory, useLocation } from "react-router-dom";
import excitedKitty from "../images/excitedKitty.svg";
import ErrorAlert from "modules/common/components/ErrorAlert";
import { useMutation } from "react-query";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ResetPassword = () => {
  const query = useQuery();
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const history = useHistory();
  const resetPasswordMutation = useMutation(resetPassword, {
    onSuccess: () => {
      history.push("/login");
    },
  });

  function submitResetPassword(e) {
    e.preventDefault();
    const requestBody = {
      password: password,
      verifyPassword: verifyPassword,
      userId: query.get("id"),
      token: query.get("token"),
    };
    resetPasswordMutation.mutate(requestBody);
  }

  return (
    <div className="h-full flex-col">
      <div className="m-auto mt-20 mx-4 text-center">
        <img
          className="h-52 m-auto mt-20 mb-4"
          src={excitedKitty}
          alt="Workflow"
        />
        <div className="text-4xl font-sans font-light mb-2">
          Reset your password
        </div>
        <form onSubmit={submitResetPassword} className="max-w-md m-auto">
          <input
            onChange={(e) => setPassword(e.target.value)}
            id="password"
            name="password"
            type="password"
            autoComplete="password"
            required
            className="appearance-none rounded-t-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            placeholder="Password"
          />
          <input
            onChange={(e) => setVerifyPassword(e.target.value)}
            id="password"
            name="password"
            type="password"
            autoComplete="password"
            required
            className="appearance-none rounded-b-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            placeholder="Verify Password"
          />
          <button
            type="submit"
            className="w-full border my-4 rounded-md bg-theme-red-300 text-white text-sm py-2 px-4 hover:bg-theme-red-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-theme-red-400"
          >
            Submit
          </button>
          {resetPasswordMutation.isError && (
            <ErrorAlert
              errorMessage={
                resetPasswordMutation.error.response.data.errorMessage
              }
            />
          )}
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
