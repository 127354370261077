import React from "react";

const HeroImageTitle = ({ image, title, subTitle }) => {
  return (
    <div
      style={{
        backgroundImage: ` linear-gradient(to bottom, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.000), rgba(0,0,0,0)), url(${image})`,
        animation: "fadein 1s",
      }}
      className="h-screen bg-cover bg-center w-full pt-40 xl:pt-60 px-8 bg-black"
    >
      <div className="flex flex-col justify-center items-center">
        <h1 className="mb-2 text-white text-4xl lg:text-6xl capitalize">
          {title}
        </h1>

        <p className="align-top text-lg lg:text-2xl text-gray-300 font-thin border-gray-300 border-t pt-1">
          {subTitle}
        </p>
      </div>
    </div>
  );
};

export default HeroImageTitle;
