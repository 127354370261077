import React from "react";

const TextField = ({
  text,
  setText,
  title,
  placeholder,
  className,
  required,
  displayAsterick,
  maxLength,
  maxLengthArea,
  autoComplete,
  uniqueKey,
  isTextArea,
  textAreaRows,
}) => {
  return (
    <div className={className} key={uniqueKey + "-text"}>
      {displayAsterick && (
        <div className="inline text-theme-red-400 font-medium mg:text-lg">
          {"* "}
        </div>
      )}
      {title && (
        <label
          htmlFor={uniqueKey + "-text"}
          className="inline font-medium text-gray-700 md:text-lg"
        >
          {title}
        </label>
      )}
      {isTextArea ? (
        <textarea
          id={uniqueKey + "-textarea"}
          name={uniqueKey + "-textarea"}
          autoComplete={autoComplete}
          type="text"
          value={text || ""}
          onChange={(e) => setText(e.target.value)}
          maxLength={maxLengthArea}
          rows={textAreaRows}
          className="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-3 focus:outline-none focus:ring-theme-purple-400 focus:border-theme-purple-400  focus:border-2 sm:text-base"
          placeholder={placeholder}
          required={required}
        />
      ) : (
        <input
          id={uniqueKey + "-text"}
          name={uniqueKey + "-text"}
          autoComplete={autoComplete}
          type="text"
          value={text || ""}
          onChange={(e) => setText(e.target.value)}
          maxLength={maxLength}
          className="mt-1 h-12 block w-full border-gray-300 rounded-md shadow-sm px-3 focus:outline-none focus:ring-theme-purple-400 focus:border-theme-purple-400  focus:border-2 sm:text-base"
          placeholder={placeholder}
          required={required}
        />
      )}
    </div>
  );
};
TextField.defaultProps = {
  displayAsterick: false,
  required: false,
  maxLength: 20,
  maxLengthArea: 100,
  autoComplete: "off",
  isTextArea: false,
  textAreaRows: "3",
};

export default TextField;
