import React from "react";

const PagePadding = () => {
  return (
    <div
      className="w-full h-16 sm:h-24"
      style={{ background: "radial-gradient(#462980, #231440)" }}
    />
  );
};

export default PagePadding;
