import React from "react";
import LazyLoad from "react-lazyload";
import EventCard from "./EventCard";
import { useQuery } from "react-query";
import { getEvents } from "api/Queries";

const EventGallery = ({ displayName }) => {
  const eventQuery = useQuery(displayName + "events", () =>
    getEvents(displayName)
  );
  return (
    <div className="gap-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 lg:gap-6">
      {eventQuery.status === "success" &&
        eventQuery.data.map((event) => (
          <div key={event._id} className="col-span-1">
            <LazyLoad offset={500}>
              <EventCard event={event} />
            </LazyLoad>
          </div>
        ))}
    </div>
  );
};

export default EventGallery;
