import React, { useEffect } from "react";
import ErrorAlert from "./ErrorAlert";

const FormModal = ({
  onSubmit,
  showModal,
  setShowModal,
  disableSubmit,
  errorMessage,
  formComponent,
  modalTitle,
}) => {
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showModal]);

  return (
    <>
      {showModal && (
        <div
          className="bg-opacity-25 fixed inset-0 z-40 bg-black"
          onClick={() => setShowModal(false)}
        >
          <form
            className="m-auto fixed justify-center bg-opacity-0 items-center flex flex-grow overflow-x-hidden overflow-y-auto inset-0 z-50 outline-none focus:outline-none px-2 sm:px-0"
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            <div
              className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none"
              onClick={(e) => e.stopPropagation()}
            >
              {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                <h3 className="text-3xl font-semibold">{modalTitle}</h3>
              </div>
              {/*body*/}
              {formComponent}
              {/*footer*/}
              <div className="flex flex-col items-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                <div className="flex flex-row">
                  <button
                    className="text-red-500 font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className="bg-theme-red-300 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                    type="submit"
                    disabled={disableSubmit}
                  >
                    Submit
                  </button>
                </div>
                {errorMessage && <ErrorAlert errorMessage={errorMessage} />}
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

FormModal.defaultProps = {
  showModal: true,
};

export default FormModal;
