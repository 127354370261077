import React, { useState } from "react";
import lostKitten from "../images/thinkingKitty.svg";
import { sendForgotPasswordEmailToUser } from "api/Queries";
import InfoAlert from "modules/common/components/InfoAlert";
import ErrorAlert from "modules/common/components/ErrorAlert";
import { useMutation } from "react-query";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const mutation = useMutation(sendForgotPasswordEmailToUser);

  function sendForgotPasswordEmail(e) {
    e.preventDefault();
    mutation.mutate({ email: email });
  }

  return (
    <div className="h-full flex-col m-auto">
      <img className="h-52 m-auto mt-20 mb-4" src={lostKitten} alt="Workflow" />
      <form className="px-4 max-w-md" onSubmit={sendForgotPasswordEmail}>
        <div className="text-center mx-4 mb-4 font-sans font-light">
          Don’t worry, we all forget sometimes. Enter your email address and we
          will send you a link to reset your password.{" "}
        </div>
        <input
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          id="email-address"
          name="email"
          type="email"
          autoComplete="email"
          required
          className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
          placeholder="Email address"
        />
        <button
          type="submit"
          disabled={mutation.isLoading || mutation.isSuccess}
          className="w-full border my-4 rounded-md bg-theme-red-300 text-white text-sm py-2 px-4 hover:bg-theme-red-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-theme-red-400 disabled:bg-opacity-30"
        >
          Submit
        </button>
        {mutation.isSuccess && (
          <InfoAlert infoMessage="Email sent! Check your inbox for instructions on how to reset your password." />
        )}
        {mutation.isError && (
          <ErrorAlert errorMessage="Something went wrong with sending the email. Please click Submit to try again." />
        )}
      </form>
    </div>
  );
};

export default ForgotPassword;
