import React, { useState } from "react";
import { getTripById, updateTripForUser } from "api/Queries";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import SingleImageUpload from "modules/common/components/SingleImageUpload";
import TextField from "modules/common/components/fields/TextField";
import CostCurrencyField from "modules/common/components/fields/CostCurrencyField";
import DateRangePicker from "modules/common/components/fields/DateRangePicker";
import { defaultImages } from "modules/common/images/CommonImageLinks";
import ErrorAlert from "modules/common/components/ErrorAlert";
import PagePadding from "modules/navbar/components/PagePadding";

const EditTrip = () => {
  const [tripTitle, setTripTitle] = useState("");
  const [tripDescription, setTripDescription] = useState("");
  const [tripImageUrl, setTripImageUrl] = useState("");
  const [budget, setBudget] = useState(undefined);
  const [currency, setCurrency] = useState("usd");
  const [tripStartDate, setTripStartDate] = useState(undefined);
  const [tripEndDate, setTripEndDate] = useState(undefined);
  const [imageUploadError, setImageUploadError] = useState("");
  const [imageLoading, setImageLoading] = useState(false);

  const history = useHistory();

  const { id: tripId } = useParams();
  const { data } = useQuery(tripId, () => getTripById(tripId));
  const updateTripMutation = useMutation(
    "updateTrip-" + tripId,
    updateTripForUser,
    {
      onSuccess: () => {
        history.push("/Trips");
      },
    }
  );

  function updateTripById(e) {
    e.preventDefault();
    updateTripMutation.mutate({
      tripId: tripId,
      updateTripFormBody: {
        title: tripTitle || data.data.title,
        description: tripDescription || data.data.description,
        budget: budget || data.data.budget,
        currency: currency || data.data.currency,
        startDate: tripStartDate || new Date(data.data.startDate),
        endDate: tripEndDate || new Date(data.data.endDate),
        image: tripImageUrl || data.data.image,
        attendees: data.data.attendees,
      },
    });
  }

  return (
    <>
      <PagePadding />
      <form
        className="container w-full px-4 sm:px-6 lg:px-8 pt-4"
        onSubmit={updateTripById}
      >
        <div className="grid grid-cols-4 gap-2">
          {data && data.data && (
            <div className="col-span-4">
              <h1 className=" font-medium text-4xl border-b-2 mb-2">
                Trip Details
              </h1>
              <h1 className="font-medium text-2xl pb-4">Cover Photo</h1>
              <SingleImageUpload
                folder="trips"
                imageUrlState={tripImageUrl || data.data.image}
                setImageUrlState={setTripImageUrl}
                defaultImage={defaultImages.defaultTripImageUrl}
                setError={setImageUploadError}
                loading={imageLoading}
                setLoading={setImageLoading}
              />
              {imageUploadError && (
                <ErrorAlert errorMessage={imageUploadError} />
              )}
              <div>
                <h1 className="my-2 text-3xl">Details</h1>
                <TextField
                  text={tripTitle || data.data.title}
                  setText={setTripTitle}
                  title="Trip Title"
                  required
                  displayAsterick={true}
                  maxLength={50}
                  uniqueKey={tripId + "trip-title"}
                  className="pb-2"
                />
                <TextField
                  text={tripDescription || data.data.description}
                  setText={setTripDescription}
                  title="Trip Description"
                  placeholder="Enter a brief description of the trip, if you'd like."
                  maxLength={500}
                  uniqueKey={tripId + "trip-description"}
                  isTextArea={true}
                  className="pb-2"
                />
                <h2 className="inline font-medium text-gray-700 md:text-lg">
                  Dates
                </h2>
                <div className="mb-4 py-2 rounded-md border-2 border-gray-300">
                  <DateRangePicker
                    from={tripStartDate || new Date(data.data.startDate)}
                    to={tripEndDate || new Date(data.data.endDate)}
                    setFrom={setTripStartDate}
                    setTo={setTripEndDate}
                  />
                </div>
                <h1 className="my-2 text-3xl">Budget</h1>
                <CostCurrencyField
                  cost={budget || data.data.budget}
                  currency={currency || data.data.currency}
                  setCost={setBudget}
                  setCurrency={setCurrency}
                  placeholder="0.00"
                  title="Total Spending Limit"
                  required
                  uniqueKey={tripId + "trip-budget"}
                  className="pb-2"
                />
              </div>
              <button
                className="w-full bg-theme-red-300 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                type="submit"
                disabled={
                  updateTripMutation.isLoading || updateTripMutation.isSuccess
                }
              >
                Submit
              </button>
            </div>
          )}
        </div>
      </form>
    </>
  );
};

export default EditTrip;
