import React, { useEffect, useCallback } from "react";
import Autocomplete from "react-google-autocomplete";
import geoTz from "geo-tz";
import dropdownTimezones from "../helpers/DropdownData";
import { ACTIONS } from "..";

// Event component
const PointInTime = ({ dispatch, pointInTime }) => {
  const eventCreateLocation = "eventCreateLocation" + pointInTime.id;
  const eventCreateDateTime = "eventCreateDateTime" + pointInTime.id;
  const eventCreateTZ = "eventCreateTZ" + pointInTime.id;
  const eventCreateDetails = "eventCreateDetails" + pointInTime.id;

  const adjustForTimezone = (date) => {
    var timeOffsetInMS = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() - timeOffsetInMS);
    return date.toISOString().toString().slice(0, 16);
  };
  const date = new Date();

  // const testDate = new Date();
  // console.log(testDate.toUTCString("en-US"));
  const defaultDate = adjustForTimezone(date);
  const defaultTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // const otherDate = new Date(defaultDate);
  // const usaTime = otherDate.toUTCString("en-US", {
  //   timeZone: defaultTZ,
  // });
  // console.log(usaTime);

  const updatePointInTime = useCallback(
    (value, attr) => {
      dispatch({
        type: ACTIONS.UPDATE_POINT_IN_TIME,
        payload: {
          id: pointInTime.id,
          attr: attr,
          value: value,
        },
      });
    },
    [dispatch, pointInTime.id]
  );

  useEffect(() => {
    if (
      pointInTime.location &&
      pointInTime.location.latitude &&
      pointInTime.location.longitude
    )
      try {
        updatePointInTime(
          geoTz(
            pointInTime.location.latitude,
            pointInTime.location.longitude
          )[0],
          "timezone"
        );
      } catch (error) {
        console.log("No timezone found");
      }
  }, [pointInTime.location, updatePointInTime]);

  return (
    <div className="col-span-4 grid grid-cols-4 space-y-6 ">
      {pointInTime.subTitle !== "PIT" && (
        <h3 className="text-xl capitalize">{pointInTime.subTitle}</h3>
      )}
      <div className="col-span-4">
        <label
          htmlFor={eventCreateLocation}
          className="block text-sm font-medium text-gray-700"
        >
          Location
        </label>

        <Autocomplete
          onPlaceSelected={(place) => {
            place["latitude"] = place.geometry.location.lat();
            place["longitude"] = place.geometry.location.lng();
            updatePointInTime(place, "location");
          }}
          fields={[
            "address_component",
            "photos",
            "adr_address",
            "business_status",
            "formatted_address",
            "geometry",
            "icon",
            "name",
            "business_status",
            "photo",
            "place_id",
            "plus_code",
            "type",
            "url",
            "utc_offset_minutes",
            "vicinity",
          ]}
          types={["geocode", "establishment"]}
          type="text"
          onFocus={(e) => e.target.setAttribute("autocomplete", "off")}
          id={eventCreateLocation}
          name={eventCreateLocation}
          className="max-w-md mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          // onChange={(e) => setGoogleAddress(e.target.value)}
        />
      </div>

      <div className="col-span-4">
        <label
          htmlFor={eventCreateDateTime}
          className="block text-sm font-medium text-gray-700"
        >
          Date/Time
        </label>
        <input
          id={eventCreateDateTime}
          name={eventCreateDateTime}
          type="datetime-local"
          defaultValue={defaultDate}
          autoComplete="off"
          onChange={(e) => updatePointInTime(e.target.value, "dateTime")}
          className="max-w-md mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        ></input>
      </div>
      <div className="col-span-2">
        <label
          htmlFor={eventCreateTZ}
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Timezone
        </label>
        <div className="mt-1">
          <select
            id={eventCreateTZ}
            name={eventCreateTZ}
            defaultValue={defaultTZ}
            value={pointInTime.timezone && pointInTime.timezone}
            onChange={(e) => updatePointInTime(e.target.value, "timezone")}
            className="max-w-md block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
          >
            <option value="">-- select --</option>
            {dropdownTimezones?.dropdownTimezones.map((tz) => (
              <option key={tz} id={tz}>
                {tz}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="col-span-4">
        <label
          htmlFor={eventCreateDetails}
          className="block text-sm font-medium text-gray-700"
        >
          Details
        </label>
        <input
          id={eventCreateDetails}
          name={eventCreateDetails}
          autoComplete="off"
          onChange={(e) => updatePointInTime(e.target.value, "details")}
          className="max-w-md mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        ></input>
      </div>
    </div>
  );
};

export default PointInTime;
