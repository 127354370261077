import React from "react";
import Icon from "modules/common/icons";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import { postEvent } from "api/Queries";
import { eventSubTypeInfo } from "modules/common/dicts/eventSubTypeInfo";

const EventSubTypeBubble = ({ type, subType, icon, setIsOpen }) => {
  // Function to handle create event click
  // initiate history
  const history = useHistory();

  // Mutations
  const eventMutation = useMutation(postEvent, {
    onError: async (error) => {
      console.log(error.response.data.errorMessage);
    },
    onSuccess: async (response) => {
      const id = response.data._id;
      setIsOpen(false);
      history.push("/event/" + id);
    },
  });

  const adjustForTimezone = (date) => {
    var timeOffsetInMS = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() - timeOffsetInMS);
    return date.toISOString().toString().slice(0, 16);
  };

  async function createEvent(e) {
    e.preventDefault();

    const defaultTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const date = new Date();
    const defaultDate = adjustForTimezone(date);

    const eventCreateBody = {
      title: subType.toLowerCase(),
      type: type.toLowerCase(),
      subType: subType.toLowerCase(),
      pointsInTime: [
        {
          subTitle:
            eventSubTypeInfo[type.toLowerCase()].subTypes[subType.toLowerCase()]
              .pitTitles[0],
          dateTime: defaultDate,
          timezone: defaultTZ,
        },
      ],
    };
    console.log(eventCreateBody);
    // On Create Event fire mutation to createEvent
    try {
      eventMutation.mutate(eventCreateBody);
    } catch (error) {
      console.log("Event Failed to create");
    }
  }
  return (
    <button
      onClick={(e) => {
        e.stopPropagation();
        createEvent(e);
      }}
      className=" col-span-1 flex flex-col justify-center items-center outline-none z-50 focus:outline-none focus:shadow-none"
    >
      <div className="h-14 w-14 rounded-full bg-theme-red-600 flex flex-col justify-center items-center">
        <Icon icon={icon} className="text-white h-10 w-10" />
      </div>
      <p className="text-white text-xs font-medium pt-1">{subType}</p>
    </button>
  );
};

export default EventSubTypeBubble;
