import CircleCard from "modules/common/components/CircleCard";
import React from "react";

const AboutDevs = ({devs}) => {
    return (
        <div className="grid grid-cols-1 gap-14 sm:grid-cols-2 sm:gap-40 lg:grid-cols-3 lg:gap-40">
            {devs.map((dev) => (
                <div className="h-72 w-72">
                    <CircleCard title={dev.name} desc={dev.description} img={dev.image} gradient1='#693DBF' gradient2='#462980'/>
                </div>
            ))}
        </div>
    )
  };
  
  export default AboutDevs;