import React from "react";
import { NavLink } from "react-router-dom";

const MobileMenu = ({ user, logout, setShowMenu }) => {
  return (
    <>
      {user ? (
        <div
          className="sm:hidden"
          id="mobile-menu"
          onClick={() => setShowMenu(false)}
        >
          <div className="px-4 pt-2 pb-3 space-y-1 text-right border-b-2 border-purple-300">
            {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-theme-purple-600 hover:text-white" */}
            <NavLink
              to={"/" + user.displayName}
              className="text-gray-300 hover:bg-theme-purple-600 hover:text-white block px-1 py-2 text-base font-medium border-b-2 border-purple-300"
            >
              Blogs
            </NavLink>
            <NavLink
              to="/Trips"
              className="text-gray-300 hover:bg-theme-purple-600 hover:text-white block px-1 py-2 text-base font-medium border-b-2 border-purple-300"
            >
              Trips
            </NavLink>
            <NavLink
              to="/Friends/inProgress"
              className="text-gray-300 hover:bg-theme-purple-600 hover:text-white block px-1 py-2 text-base font-medium"
            >
              Friends
            </NavLink>
          </div>
          <div className="pt-4 pb-3 border-t-1 border-purple-600 text-right">
            <div className="flex items-center px-5 flex-row-reverse">
              <div className="flex-shrink-0">
                <img
                  className="h-10 w-10 rounded-full"
                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixqx=KdgQecjqs6&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                />
              </div>
              <div className="ml-3 mr-2">
                <div className="text-base font-medium text-white">
                  {user.displayName}
                </div>
                <div className="text-sm font-medium text-gray-400">
                  {user.email}
                </div>
              </div>
            </div>
            <div className="mt-3 px-2 space-y-1">
              <NavLink
                to="/Profile/inProgress"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-theme-purple-600"
              >
                Your Profile
              </NavLink>
              <NavLink
                to="/Settings/inProgress"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-theme-purple-600"
              >
                Settings
              </NavLink>
              <NavLink
                to="/"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-theme-purple-600"
                onClick={() => {
                  logout();
                  setShowMenu(false);
                }}
              >
                Sign out
              </NavLink>
            </div>
          </div>
        </div>
      ) : (
        <div className="pt-4 pb-3 border-t mt-3 px-2 space-y-1 text-right">
          <NavLink
            to="/signup"
            className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-theme-purple-600"
            onClick={() => {
              setShowMenu(false);
            }}
          >
            Sign Up
          </NavLink>
          <NavLink
            to="/login"
            className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-theme-purple-600"
            onClick={() => {
              setShowMenu(false);
            }}
          >
            Login
          </NavLink>
        </div>
      )}
    </>
  );
};

export default MobileMenu;
