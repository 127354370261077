import React, { useRef, useState, useEffect } from "react";
import Icon, { TYPES } from "modules/common/icons";
import ClipLoader from "react-spinners/ClipLoader";
import { uploadImage } from "api/Queries";
import { useMutation } from "react-query";
import imageCompression from "browser-image-compression";
import "modules/common/styles/images.css";

/**
 *
 * @param {folder} folder Folder where the image will be saved
 * @param {defaultImage} defaultImage default or current image to be displayed
 * @param { imageUrlState } imageUrlState The URL returned when the image is saved. This should be saved to the databased as the reference to the image.
 * @param { setImageUrlState } setImageUrlState The Set State for imageURLState
 * @param {loading} loading Passes out the state of the component for if it is Loading
 * @param {setLoading} setLoading Sets the loading state if image is still being processed
 * @param {setError} setError Sets a state to an error message
 * @description - This component takes in the above params.
 * It allows a user to add an image by compressing and uploading the image to google cloud.
 * The component also handles deletion of an image.
 * The image URL, loading state and Error handling is passed out of the component via useStates in the parent component.
 *
 */

const SingleImageUpload = ({
  folder,
  defaultImage,
  imageUrlState,
  setImageUrlState,
  loading,
  setLoading,
  setError,
}) => {
  // useRefs
  const inputFile = useRef(null);
  const prevDisplayedImage = useRef(null);

  // useStates
  const [loadingImage, setLoadingImage] = useState();
  const [displayedImage, setDisplayedImage] = useState();

  // When an image is added or a default is set the displayedImage is set to it.
  // If the image is deleted the displayed image is set to null.
  useEffect(() => {
    if (imageUrlState) return setDisplayedImage(imageUrlState);
    if (prevDisplayedImage.current) return setDisplayedImage(null);
    if (defaultImage) return setDisplayedImage(defaultImage);
  }, [defaultImage, imageUrlState]);

  // Mutation for posting image to Google Cloud
  const imageMutation = useMutation(uploadImage, {
    onError: async (error) => {
      console.log(error);
      setError(error.response.data.errorMessage);
    },
    onSuccess: async (data) => {
      setImageUrlState(data.image_url);
      setLoading(false);
    },
  });

  // Creates Body to be sent to Image Upload API
  const uploadToServer = async (file) => {
    const bodyFormData = new FormData();

    bodyFormData.append("image", file);
    bodyFormData.append("folder", folder);
    try {
      imageMutation.mutate(bodyFormData);
    } catch (error) {
      console.log(error);
      setError(error.response.data.errorMessage);
    }
  };

  // Function for compressing image
  async function handleImage(e) {
    e.preventDefault();

    const imageFile = e.target.files[0];

    // Sets the non compressed image to display as blurred loading image
    setLoadingImage(URL.createObjectURL(e.target.files[0]));
    if (!imageFile) return;

    setLoading(true);

    // options for compressing images
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);

      await uploadToServer(compressedFile); // write your own logic
    } catch (error) {
      console.log(error);
    }
  }

  // function to handle button click so native image file loader opens
  const onUploadButtonClick = () => {
    inputFile.current.value = "";
    inputFile.current.click();
  };

  // function for handling button click of delete
  const onDeleteButtonClick = () => {
    prevDisplayedImage.current = displayedImage;
    setLoadingImage(null);
    setImageUrlState(null);
    setDisplayedImage(null);
  };

  return (
    <>
      <input
        id="imageInput"
        type="file"
        accept="image/*"
        ref={inputFile}
        onChange={(e) => handleImage(e)}
        style={{ display: "none" }}
      />
      <div
        className="col-span-4 rounded-2xl relative overflow-hidden"
        style={{ paddingTop: "100%" }}
      >
        {!loading && !displayedImage && (
          <button
            type="button"
            id="openImageUpload"
            onClick={onUploadButtonClick}
            className="absolute top-0 w-full h-full border-2 border-dashed rounded-2xl focus:outline-none focus:ring-theme-purple-300 focus:border-theme-purple-300  "
          >
            <Icon
              className="absolute top-0 p-8 text-gray-200"
              icon={TYPES.UPLOAD}
            />
          </button>
        )}

        {loadingImage && (
          <img
            src={loadingImage}
            className="absolute top-0 h-full w-full object-cover blur"
            alt="thing"
          />
        )}

        {loading && !displayedImage && (
          <div>
            <div className="absolute top-0 p-8 w-full h-full flex items-center justify-center">
              <ClipLoader color="white" size="80%" />
            </div>
          </div>
        )}

        {displayedImage && (
          <div>
            <img
              src={displayedImage}
              className="absolute top-0 h-full w-full object-cover"
              alt="thing"
            />

            {!loading && (
              <button
                type="button"
                id="openImageUpload"
                onClick={onDeleteButtonClick}
                className="absolute bottom-0 right-0 h-1/4 w-1/4 lg:h-1/6 lg:w-1/6 rounded-2xl focus:outline-none focus:ring-theme-purple-300 focus:border-theme-purple-300 opacity-50 hover:opacity-70 "
              >
                <Icon
                  className="absolute top-0 p-4 text-gray-50"
                  icon={TYPES.DELETE}
                />
              </button>
            )}
            {loading && (
              <div className="absolute top-0 p-8 w-full h-full flex items-center justify-center">
                <ClipLoader color="white" size="80%" />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default SingleImageUpload;
