import React from "react";
import tbLogo from "modules/common/images/tblogo.png";
import PagePadding from "modules/navbar/components/PagePadding";

// Home component
const Home = () => {
  return (
    <>
      <PagePadding />
      <div className="w-full flex flex-col justify-center items-center center flex-grow -mt-28 pt-16">
        <img src={tbLogo} alt="" className="w-1/3 mb-8" />
        <h2 className="text-2xl font-medium">Welcome to TravelBudgy</h2>
      </div>
    </>
  );
};

export default Home;
