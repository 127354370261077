import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { signUpUser } from "api/Queries";
import UserContext from "context/UserContext";
import ErrorAlert from "modules/common/components/ErrorAlert";
import PagePadding from "modules/navbar/components/PagePadding";

// Signup component
const Signup = () => {
  // useStates for Form
  const [signupEmail, setSignupEmail] = useState("");
  const [signupDisplayName, setSignupDisplayName] = useState("");
  const [signupPassword, setSignupPassword] = useState("");
  const [signupPasswordVerify, setSignupPasswordVerify] = useState("");
  const [signupError, setSignupError] = useState();

  // initiate useContext
  const { getUser } = useContext(UserContext);
  // initiate history
  const history = useHistory();

  // function to handle Sign Up click
  async function signup(e) {
    e.preventDefault();
    const signupFormBody = {
      email: signupEmail,
      displayName: signupDisplayName,
      password: signupPassword,
      passwordVerify: signupPasswordVerify,
    };

    // signs up and logs in user then gets user info from cookie and redirects to home screen
    try {
      await signUpUser(signupFormBody);
      await getUser();
      history.push("/");
    } catch (error) {
      setSignupError(error.response.data.errorMessage);
    }
  }

  return (
    <>
      <PagePadding />
      <div className="flex-grow flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Create an Account
            </h2>
          </div>
          <form className="mt-8 space-y-6 relative" onSubmit={signup}>
            <input type="hidden" name="remember" value="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label forhtml="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  onChange={(e) => {
                    setSignupEmail(e.target.value);
                  }}
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                />
              </div>
              <div>
                <label forhtml="display-name" className="sr-only">
                  Display Name
                </label>
                <input
                  onChange={(e) => {
                    setSignupDisplayName(e.target.value);
                  }}
                  id="display-name"
                  name="displayName"
                  type="text"
                  autoComplete="off"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Display Name"
                />
              </div>
              <div>
                <label forhtml="password" className="sr-only">
                  Password
                </label>
                <input
                  onChange={(e) => setSignupPassword(e.target.value)}
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                />
              </div>
              <div>
                <label forhtml="password" className="sr-only">
                  Verify Password
                </label>
                <input
                  onChange={(e) => setSignupPasswordVerify(e.target.value)}
                  id="password-verify"
                  name="password-verify"
                  type="password"
                  autoComplete="new-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Verify Password"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Sign up
              </button>
            </div>
            {signupError && <ErrorAlert errorMessage={signupError} />}
          </form>
        </div>
      </div>
    </>
  );
};

export default Signup;
