import React from "react";

const CircleCard = ({ title, desc, img, gradient1, gradient2 }) => {
  return (
    <div
      style={{ background: `radial-gradient(${gradient1}, ${gradient2})` }}
      className="relative m-auto h-72 w-72 rounded-full"
    >
      <img
        className="absolute ml-auto h-24 w-24 rounded-full top-0 right-0"
        src={img}
        alt=""
      />
      <h1 className="absolute top-8 left-24 text-lg text-white font-bold">
        {title}
      </h1>
      <p className="absolute rounded-full text-center top-24 left-10 right-10 text-white text-sm">
        {desc}
      </p>
    </div>
  );
};

export default CircleCard;
