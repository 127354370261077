import React from "react";
import { Link } from "react-router-dom";
import LinesEllipsis from "react-lines-ellipsis";

const BlogCard = ({ blog, maxLines }) => {
  return (
    <div className="z-10">
      <Link to={"/blog/" + blog._id}>
        <div
          className="bg-cover bg-center w-full flex justify-end flex-col relative"
          style={{
            backgroundImage: ` url(
                         "${blog.image}"
                        )`,
            animation: "fadein 1s",
            paddingBottom: "130%",
          }}
        ></div>
        <h3 className="pt-4 font-medium text-xl">{blog.title}</h3>
        <div className="pt-2">
          <LinesEllipsis
            text={blog.description}
            maxLine={maxLines}
            ellipsis="..."
            trimRight
            basedOn="letters"
          />
        </div>
        <div className="pt-4">
          <div className="bg-indigo-500 py-1 px-2 w-24 rounded text-white flex justify-center hover:bg-indigo-800">
            View Blog
          </div>
        </div>
      </Link>
    </div>
  );
};

export default BlogCard;
