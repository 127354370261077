import React, { useContext, useState } from "react";
import { useHistory, NavLink } from "react-router-dom";
import UserContext from "context/UserContext";
import { logOutUser } from "api/Queries";
import tblogo from "modules/common/images/tblogo.png";
import DesktopProfileDropdown from "./components/DesktopProfileDropdown";
import MobileMenu from "./components/MobileMenu";

// Navbar component
const Navbar = () => {
  // get context for user
  const { user, getUser } = useContext(UserContext);

  const [showMenu, setShowMenu] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);

  // initiate history
  const history = useHistory();

  // function to call logout endpoint
  async function logout() {
    try {
      await logOutUser();
      await getUser();
      history.push("/");
    } catch (error) {
      console.log({ error });
    }
  }

  return (
    <>
      <nav
        style={
          showMenu
            ? { background: "radial-gradient(#462980, #231440)" }
            : { background: "" }
        }
        className="absolute w-full z-50"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-12 md:px-20 lg:px-4 xl:px-0 ">
          <div className="flex items-center justify-between h-16 sm:h-24">
            <div className="flex items-center">
              <NavLink className="flex-shrink-0" to="/">
                <img
                  className=" h-8 sm:h-12 w-auto"
                  src={tblogo}
                  alt="Workflow"
                />
              </NavLink>
              <div className="hidden sm:block sm:ml-6">
                <div className="flex space-x-4">
                  {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-theme-purple-600 hover:text-white" */}
                  <NavLink
                    to="/Trips"
                    className="text-white px-3 py-2 hover:text-red-100 rounded-md lg:text-lg font-medium"
                    activeClassName="pointer-events-none underline"
                  >
                    Trips
                  </NavLink>
                  <NavLink
                    to={user ? "/" + user.displayName : "/Blogs"}
                    className="text-white px-3 py-2 hover:text-red-100 rounded-md lg:text-lg font-medium"
                    activeClassName="pointer-events-none underline"
                  >
                    Blogs
                  </NavLink>
                  <NavLink
                    to="/Friends/inProgress"
                    className="text-white px-3 py-2 hover:text-red-100 rounded-md lg:text-lg font-medium"
                    activeClassName="pointer-events-none underline"
                  >
                    Friends
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="hidden sm:ml-6 sm:block">
              <div className="flex items-center">
                {/* Profile dropdown */}
                {user ? (
                  <div className="ml-3 relative">
                    <div>
                      <button
                        className="bg-gray-800 flex text-sm rounded-full focus:outline-none"
                        id="user-menu"
                        aria-haspopup="true"
                        onClick={() => {
                          setShowProfileMenu(!showProfileMenu);
                        }}
                      >
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 sm:h-12 sm:w-12 rounded-full"
                          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixqx=KdgQecjqs6&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                          alt=""
                        />
                      </button>
                    </div>
                    {/*
                      Profile dropdown panel, show/hide based on dropdown state.

                      Entering: "transition ease-out duration-100"
                        From: "transform opacity-0 scale-95"
                        To: "transform opacity-100 scale-100"
                      Leaving: "transition ease-in duration-75"
                        From: "transform opacity-100 scale-100"
                        To: "transform opacity-0 scale-95"
                    */}
                    {showProfileMenu && (
                      <DesktopProfileDropdown
                        setShowProfileMenu={setShowProfileMenu}
                        logout={logout}
                      />
                    )}
                  </div>
                ) : (
                  <>
                    <NavLink
                      to="/signup"
                      className="text-gray-300 hover:bg-theme-purple-600 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                    >
                      Sign Up
                    </NavLink>
                    <NavLink
                      to="/login"
                      className="text-gray-300 hover:bg-theme-purple-600 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                    >
                      Login
                    </NavLink>
                  </>
                )}
              </div>
            </div>
            <div className="-mr-2 flex sm:hidden">
              {/* Mobile menu button */}
              <button
                type="button"
                className="inline-flex items-center justify-center p-2 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
                onClick={() => {
                  setShowMenu(!showMenu);
                }}
              >
                <span className="sr-only">Open main menu</span>
                {/* Icon when menu is closed. */}
                {/*
      Heroicon name: outline/menu

      Menu open: "hidden", Menu closed: "block"
    */}
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
                {/* Icon when menu is open. */}
                {/*
      Heroicon name: outline/x

      Menu open: "block", Menu closed: "hidden"
    */}
                <svg
                  className="hidden h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        {/* Mobile menu, show/hide based on menu state. */}
        {showMenu && (
          <MobileMenu user={user} setShowMenu={setShowMenu} logout={logout} />
        )}
      </nav>
    </>
  );
};

export default Navbar;
