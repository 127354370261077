import React from "react";

const SelectField = ({
  selected,
  setSelected,
  title,
  hasDefault,
  className,
  options,
  required,
  displayAsterick,
  uniqueKey,
}) => {
  return (
    <div className={className} key={uniqueKey + "-selector"}>
      {displayAsterick && (
        <div className="inline text-theme-red-400 font-medium mg:text-lg">
          {"* "}
        </div>
      )}
      {title && (
        <label
          htmlFor={uniqueKey + "-selector"}
          className="inline font-medium text-gray-700 md:text-lg"
        >
          {title}
        </label>
      )}

      <div className="mt-1">
        <select
          required={required}
          value={selected || ""}
          id={uniqueKey + "-selector"}
          name={uniqueKey + "-selector"}
          onChange={(e) => setSelected(e.target.value)}
          className="h-12 block focus:ring-theme-purple-400 focus:border-theme-purple-400 w-full shadow-sm sm:text-base border-gray-300 rounded-md"
        >
          {hasDefault && (
            <option disabled key="default" value="">
              -- select --
            </option>
          )}
          {options &&
            options.map((option) => {
              return (
                <option key={option} value={option}>
                  {option}
                </option>
              );
            })}
        </select>
      </div>
    </div>
  );
};
SelectField.defaultProps = {
  title: "Select",
  hasDefault: true,
  required: false,
  displayAsterick: false,
};

export default SelectField;
