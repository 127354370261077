import React from "react";

const InfoAlert = ({ infoMessage }) => {
  return (
    <div className="w-full flex justify-center text-center left-0 items-center font-medium py-1 px-2 rounded-md text-green-700 bg-green-100 border border-green-300 ">
      <p className="text-sm font-normal flex-initial">{infoMessage}</p>
    </div>
  );
};

export default InfoAlert;
