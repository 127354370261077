import React from "react";
import Icon from "modules/common/icons";
import LeafletMap from "./LeafletMap";
import { eventSubTypeInfo } from "modules/common/dicts/eventSubTypeInfo";

const TimeLocSection = ({ eventData }) => {
  return (
    <div className="" id="time-and-location">
      {eventData?.pointsInTime && eventData?.pointsInTime?.length > 0 && (
        <div className="sm:mt-8 grid lg:grid-cols-5 sm:rounded-3xl overflow-hidden text-theme-purple-700">
          <div className="sm:bg-white bg-opacity-90 lg:col-span-3 sm:rounded-t-xl lg:rounded-l-xl lg:rounded-r-none overflow-hidden ">
            {eventData.pointsInTime.map((pit, index) => (
              <div key={pit + index} className="relative  ">
                <div className="flex p-4 sm:p-6">
                  <div className="flex-grow flex items-center">
                    <div className="h-10 w-10 bg-theme-purple-600 rounded-full">
                      <Icon
                        icon={
                          eventSubTypeInfo[eventData.type].subTypes[
                            eventData.subType
                          ].icon
                        }
                        className="text-white m-2"
                      ></Icon>
                    </div>
                    <div className="ml-4">
                      <h2 className="text-lg font-medium capitalize leading-4">
                        {pit.subTitle}
                      </h2>
                      <div>
                        <p className="text-sm text-theme-purple-600 mt-1">
                          {pit.city}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="text-right">
                    <h2 className="text-sm ">
                      {new Date(pit.dateTime).toDateString()}
                    </h2>
                    <h2 className="text-xl text-theme-red-500 font-medium ">
                      {new Date(pit.dateTime).toLocaleTimeString("en-US", {
                        timeStyle: "short",
                      })}
                    </h2>
                    <h2 className="text-sm  font-light">{pit.timezone}</h2>
                  </div>
                </div>
                {index + 1 < eventData.pointsInTime.length && (
                  <div className="pl-6 sm:pl-9 h-8 sm:h-12 w-12 flex justify-center items-center">
                    <div className="h-8 sm:h-12 border-l-2"></div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="h-full overflow-auto lg:col-span-2">
            {eventData.pointsInTime[0].latitude &&
              eventData.pointsInTime[0].longitude && (
                <div className="h-full">
                  <LeafletMap pointsInTime={eventData.pointsInTime} />
                </div>
              )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TimeLocSection;
