import React, { useState, useEffect } from "react";
import { motion, useSpring, useAnimation } from "framer-motion";

const BaseTemplate = ({ LeftPanel, RightPanel, TopPanel, BottomPanel }) => {
  const controls = useAnimation();
  const y = useSpring(0, { stiffness: 2000, damping: 100 });
  // const initialH = window.screen.height * -0.4;
  // const yInput = [initialH, 0];
  // const height = useTransform(y, yInput, ["90vh", "50vh"]);
  // const templateRef = useRef();

  const [resize, setResize] = useState("min");

  const resizeVariant = {
    min: { height: "50vh", transition: { duration: 0.3 } },
    max: {
      height: "90vh",
      transition: { duration: 0.3 },
    },
  };

  useEffect(() => {
    controls.start(resize);
  }, [controls, resize]);

  return (
    <div
      className="flex-grow bg-theme-purple-700 flex lg:p-4 xl:p-8 lg:pt-28 xl:pt-28 "
      style={{
        background:
          "linear-gradient(112.3deg, #231440 -3.17%, #462980 102.75%), linear-gradient(115.07deg, #231440 -9.3%, #693DBF 107.52%)",
      }}
    >
      {/* Desktop */}
      <div className="hidden  flex-grow rounded-3xl bg-white bg-opacity-80 lg:grid lg:grid-cols-12 max-w-7xl mx-auto">
        <div className=" col-span-4 p-4 sm:p-12">{LeftPanel}</div>

        <div className=" col-span-8 bg-white bg-opacity-30 rounded-r-3xl p-4 sm:p-12 lg:px-20">
          {RightPanel}
        </div>
      </div>
      {/* Mobile */}
      <div className="flex-grow flex flex-col relative lg:hidden">
        <div className="h-3/5 relative">{TopPanel}</div>
        <motion.div
          className="absolute z-40 bottom-0 min-h-1/2 flex flex-col items-center rounded-t-4xl w-full p-4 pt-6 sm:px-12 sm:p-8"
          style={{
            background:
              "linear-gradient(90deg, #FFFBFC -3.71%, rgba(255, 255, 255, 1) 105.44%)",

            maxHeight: "90%",
          }}
          initial="min"
          animate={controls}
          variants={resizeVariant}
        >
          <motion.div
            style={{ y }}
            drag="y"
            dragConstraints={{ top: 0, bottom: 0 }}
            onPanEnd={(event, info) => {
              if (info.offset.y < 0) return setResize("max");
              if (info.offset.y > 0) return setResize("min");
            }}
            className="absolute z-50 top-0 h-24 w-full bg-red-300 bg-opacity-0"
          ></motion.div>
          <div className="relative border-2 border-gray-100 rounded w-20 mb-4"></div>
          {BottomPanel}
        </motion.div>
      </div>
    </div>
  );
};

export default BaseTemplate;
