import React from "react";
import { NavLink } from "react-router-dom";

const DesktopProfileDropdown = ({ setShowProfileMenu, logout }) => {
  return (
    <div
      className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
      aria-orientation="vertical"
      aria-labelledby="user-menu"
      onClick={() => {
        setShowProfileMenu(false);
      }}
      onBlur={() => {
        setShowProfileMenu(false);
      }}
    >
      <NavLink
        to="/Profile/inProgress"
        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
      >
        Your Profile
      </NavLink>
      <NavLink
        to="/Settings/inProgress"
        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
      >
        Settings
      </NavLink>
      <NavLink
        to="/"
        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
        onClick={() => {
          logout();
        }}
      >
        Sign out
      </NavLink>
    </div>
  );
};

export default DesktopProfileDropdown;
