import React, { useContext } from "react";
import { Switch, Route } from "react-router-dom";
import UserHome from "modules/userhome";
import Home from "modules/home";
import Login from "modules/login";
import Signup from "./modules/signup";
import About from "./modules/about";
import Event from "modules/event";
import Blog from "modules/blog";
import EventCreate from "modules/eventcreate";
import BlogCreate from "modules/blogcreate";
import NotFoundPage from "modules/notfoundpage";
import UserContext from "context/UserContext";
import ForgotPassword from "modules/forgotpassword/components/ForgotPassword";
import ResetPassword from "modules/forgotpassword/components/ResetPassword";
import AllTrips from "modules/trips";
import EditTrip from "modules/trips/components/EditTrip";
import { ScrollToTop } from "modules/common/components";
import Navbar from "modules/navbar";
import EventEdit from "modules/eventedit";

const Router = () => {
  const { user } = useContext(UserContext);

  return (
    <>
      <Navbar />
      <ScrollToTop />
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/signup">
          <Signup />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/blog/create">
          {user && <BlogCreate displayName={user.displayName} />}
        </Route>
        <Route path="/blog/:id">
          <Blog />
        </Route>
        <Route path="/event/create">
          <EventCreate />
        </Route>
        <Route path="/event/:id/edit">
          <EventEdit />
        </Route>
        <Route path="/event/:id">
          <Event />
        </Route>
        <Route path="/forgotPassword">
          <ForgotPassword />
        </Route>
        <Route path="/resetPassword">
          <ResetPassword />
        </Route>
        <Route path="/trips" exact>
          <AllTrips />
        </Route>
        <Route path="/trips/:id/edit">
          <EditTrip />
        </Route>
        <Route path="/:urlDisplayName" exact>
          <UserHome />
        </Route>

        <Route path="/" exact>
          <Home />
        </Route>
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </>
  );
};

export default Router;
