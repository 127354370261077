import React, { useState, useEffect } from "react";
import TextField from "modules/common/components/fields/TextField";

const BasicInfoSection = ({ handleEventUpdate, eventForm }) => {
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();

  useEffect(() => {
    handleEventUpdate("title", title);
  }, [title, handleEventUpdate]);

  useEffect(() => {
    handleEventUpdate("description", description);
  }, [description, handleEventUpdate]);

  return (
    <>
      <h3 className="col-span-4 text-2xl">Details</h3>

      <TextField
        text={title ? title : eventForm.title}
        setText={setTitle}
        required={true}
        placeholder="Name this event"
        title="Title"
        displayAsterick={true}
        uniqueKey="event-title"
        className="col-span-4"
      />

      <TextField
        text={description ? description : eventForm.description}
        setText={setDescription}
        placeholder="Name this event"
        title="Title"
        uniqueKey="event-description"
        className="col-span-4"
        isTextArea={true}
      />
    </>
  );
};

export default BasicInfoSection;
