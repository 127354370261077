import React from "react";
import TextField from "modules/common/components/fields/TextField";
import DateRangePicker from "modules/common/components/fields/DateRangePicker";

const CreateTripForm = ({
  setTripTitle,
  tripTitle,
  to,
  setTo,
  from,
  setFrom,
}) => {
  return (
    <div className="relative py-2 px-4">
      <TextField
        setText={setTripTitle}
        text={tripTitle}
        title="Give the trip a name"
        placeholder="Vegas 2021 Baby!"
        maxLength={50}
        required={true}
        className="pb-2"
        uniqueKey="trip-title"
      />
      <DateRangePicker to={to} setTo={setTo} from={from} setFrom={setFrom} />
    </div>
  );
};

export default CreateTripForm;
