import React, { useState } from "react";
import Icon from "modules/common/icons";
import { eventSubTypeInfo } from "modules/common/dicts/eventSubTypeInfo";
import Slider from "modules/common/components/Slider";
import TimeLocSection from "./TimeLocSection";
import { useParams, useHistory } from "react-router-dom";

const DescriptionPanel = ({ description }) => {
  return (
    <div className="mt-4 text-sm leading-12 font-light text-gray-400">
      {description
        ? description
        : "Please edit this event to add a description."}
    </div>
  );
};

const BottomPanel = ({ data, status }) => {
  console.log(data);
  const { id } = useParams();
  const history = useHistory();
  const [sliderPosition, setSliderPosition] = useState(0);

  return (
    <div className="h-full w-full flex flex-col">
      {status === "success" ? (
        <>
          <div className="text-gray-400 w-full">
            <div name="section-1" className="flex w-full justify-between">
              <h3 className="text-theme-purple-700 font-medium text-3xl mr-8 sm:max-w-full truncate whitespace-nowrap">
                {data.title}
              </h3>
              <h3 className="text-theme-red-300  text-3xl">
                {data.cost && `$${data.cost}`}
              </h3>
            </div>
            <div
              name="section-2"
              className="flex w-full items-center justify-between mt-2 font-light"
            >
              <p className="capitalize flex items-center">
                <span className="bg-theme-purple-300 h-5 w-5 rounded-full mr-2 flex justify-center items-center p-1">
                  <Icon
                    icon={eventSubTypeInfo[data.type].icon}
                    className="text-white inline w-auto"
                  ></Icon>
                </span>
                {data.subType}
              </p>
              {data.quality && <p className=" ">* {data.quality}</p>}
            </div>
            <div
              name="section-3"
              className="grid grid-cols-3 w-full mt-8 text-sm"
            >
              <div className="flex">
                <div
                  className={`${
                    sliderPosition === 0 && "text-theme-red-300 font-medium"
                  } flex flex-col items-center`}
                >
                  <button
                    onClick={() => setSliderPosition(0)}
                    className="focus:outline-none"
                  >
                    Description
                  </button>
                  <p
                    className={`${
                      sliderPosition === 0 ? "opacity-1" : "opacity-0"
                    } -mt-2 font-extrabold text-base`}
                  >
                    .
                  </p>
                </div>
              </div>
              <div className="flex justify-center">
                <div
                  className={`${
                    sliderPosition === 1 && "text-theme-red-300 font-medium"
                  } flex flex-col items-center`}
                >
                  <button
                    onClick={() => setSliderPosition(1)}
                    className="focus:outline-none"
                  >
                    Details
                  </button>
                  <p
                    className={`${
                      sliderPosition === 1 ? "opacity-1" : "opacity-0"
                    } -mt-2 font-extrabold text-base`}
                  >
                    .
                  </p>
                </div>
              </div>
              <div className="flex justify-end">
                <div
                  className={`${
                    sliderPosition === 2 && "text-theme-red-300 font-medium"
                  } flex flex-col items-center`}
                >
                  <button
                    onClick={() => setSliderPosition(2)}
                    className="focus:outline-none"
                  >
                    Comments
                  </button>
                  <p
                    className={`${
                      sliderPosition === 2 ? "opacity-1" : "opacity-0"
                    } -mt-2 font-extrabold text-base`}
                  >
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Slider
            panels={[
              <DescriptionPanel description={data.description} />,
              <TimeLocSection eventData={data} />,
              <div className="text-gray-400 text-sm mt-4">No Comments...</div>,
            ]}
            position={sliderPosition}
            setPosition={setSliderPosition}
          />
          <button
            onClick={() => history.push("/event/" + id + "/edit")}
            className="fixed z-50  bottom-4 right-4  bg-theme-red-300 px-3 py-0 rounded-2xl text-white text-lg"
          >
            Edit
          </button>
        </>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default BottomPanel;
