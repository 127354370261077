import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import MDEditor from "@uiw/react-md-editor";
import LazyLoad from "react-lazyload";
import Footer from "../common/components/Footer";
import { getBlog } from "../../api/Queries";

// Blogs component
const Blog = () => {
  const { id } = useParams();
  const blogIdQuery = useQuery(id, () => getBlog(id));
  console.log(blogIdQuery);

  return (
    <div className="top--24 w-full">
      {blogIdQuery.status === "loading" && <div className="h-screen"></div>}
      {blogIdQuery.status === "success" && (
        <div>
          <div
            style={{
              backgroundImage: ` linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.30)), url(${blogIdQuery.data.image})`,
              animation: "fadein 1s",
            }}
            className="h-screen bg-cover bg-center text-center px-4 py-14 sm:px-4 lg:px-24 flex items-center justify-center"
          >
            <div className="grid grid-cols-4 w-full">
              <div className="col-span-4 uppercase text-3xl text-white lg:text-6xl">
                <h1 className="mb-2">{blogIdQuery.data.title}</h1>
              </div>
            </div>
          </div>
          <div className=" container  mx-auto px-8 pt-20 sm:px-8 lg:px-52 xl:px-72">
            <div className="">
              <div className="text-sm font-light text-gray-900">
                Author: {blogIdQuery.data.creator.email}
              </div>
              <div className="text-gray-600 text-xs font-light">
                Updated: {new Date(blogIdQuery.data.updatedAt).toDateString()}
              </div>
            </div>
            <MDEditor.Markdown
              className="py-10 text-gray-700"
              source={blogIdQuery.data.description}
            />
            {blogIdQuery.data.blogSections.map((blogSection) => (
              <div key={blogSection._id}>
                <LazyLoad>
                  <img src={blogSection.image} alt="" className="h-30" />
                  <h3 className="font-medium text-2xl pt-12">
                    {blogSection.title}
                  </h3>
                  <MDEditor.Markdown
                    className="py-12 text-gray-700"
                    source={blogSection.description}
                  />
                </LazyLoad>
              </div>
            ))}
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Blog;
