import React from "react";
import { motion } from "framer-motion";

const Slider = ({ panels, position, setPosition }) => {
  return (
    <div className="relative z-20 overflow-x-hidden -mx-2 px-2 h-full">
      <motion.div
        className="relative z-30 flex h-full  "
        drag="x"
        dragConstraints={{ left: 0, top: 0, bottom: 0, right: 0 }}
        onPanEnd={(event, info) => {
          if (info.offset.x < -50 && info.offset.y > -200) {
            if (position + 1 >= panels.length) return;
            return setPosition((prevPosition) => prevPosition + 1);
          }
          if (info.offset.x > 50) {
            if (position - 1 < 0) return;
            return setPosition((prevPosition) => prevPosition - 1);
          }
        }}
      >
        {panels.map((panel, index) => (
          <motion.div
            className="absolute w-full h-full  h-auto"
            key={"panel-" + index}
            initial={{ opacity: 0.5, left: `${(index - position) * 100}vw` }}
            animate={{
              opacity: index === position ? 1 : 0.5,
              left: `${(index - position) * 100}vw`,
            }}
            transition={{
              type: "spring",
              stiffness: 120,
              damping: 20,
              duration: 1,
            }}
          >
            {panel}
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

Slider.defaultProps = {
  position: 0,
  height: "20vh",
};

export default Slider;
