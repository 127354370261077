import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "styles/Images.css";
// import MarkerClusterGroup from "react-leaflet-markercluster";

const LeafletMap = ({ pointsInTime }) => {
  return (
    <div className="h-full">
      <MapContainer
        center={[pointsInTime[0].latitude, pointsInTime[0].longitude]}
        zoom={10}
        scrollWheelZoom={false}
        className="h-44 lg:h-full relative z-40 sm:rounded-b-xl lg:rounded-r-xl lg:rounded-l-none shadow-sm"
        dragging={false}
      >
        <TileLayer
          // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
        />

        {pointsInTime.map((pointInTime, index) => {
          if (pointInTime.latitude && pointInTime.longitude) {
            return (
              <Marker
                key={index}
                position={[pointInTime.latitude, pointInTime.longitude]}
              >
                <Popup>
                  <div> {pointInTime.subTitle}</div>
                  <div>{pointInTime.formattedAddress}</div>
                </Popup>
              </Marker>
            );
          }
          return null;
        })}
      </MapContainer>
    </div>
  );
};

export default LeafletMap;
