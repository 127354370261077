import React from "react";
import { useQuery } from "react-query";
import { useParams, useHistory } from "react-router-dom";

import { getEvent } from "../../api/Queries";

import TimeLocSection from "./components/TimeLocSection";

import "index.css";

import BaseTemplate from "modules/common/components/BaseTemplate";
import BottomPanel from "./components/BottomPanel";

// Events component
const Event = () => {
  const { id } = useParams();
  const eventIdQuery = useQuery(id, () => getEvent(id));
  const history = useHistory();

  console.log(eventIdQuery?.data);

  return (
    <BaseTemplate
      LeftPanel={
        <div>
          {eventIdQuery.status === "loading" && <div className=""></div>}
          {eventIdQuery.status === "success" && (
            <div>
              <div
                className="w-full bg-cover rounded-3xl "
                style={{
                  backgroundImage: `url(${eventIdQuery.data.image})`,
                  paddingTop: "80%",
                }}
              />
              <h2 className="text-theme-purple-700 text-4xl mt-6">
                {eventIdQuery.data.title}
              </h2>
              <h4 className="text-theme-purple-600">
                {new Date(
                  eventIdQuery?.data?.pointsInTime[0]?.dateTime
                ).toDateString()}
              </h4>
              <p className="text-theme-purple-700 text-sm mt-4">
                {eventIdQuery.data.description
                  ? eventIdQuery.data.description
                  : "Click edit to add a description"}
              </p>
              <div className="pb-8 lg:pb-0">
                <h3 className="text-2xl mt-8 text-theme-purple-700">Cost</h3>
                <div className="mt-2 flex justify-between items-center w-full text-xl text-theme-purple-600">
                  <p>$ {eventIdQuery.data.cost}</p>
                  <p className="opacity-30 text-base">
                    * {eventIdQuery.data.quality}
                  </p>
                </div>
                {/* <CostSection eventData={eventIdQuery.data} /> */}
              </div>
            </div>
          )}
        </div>
      }
      RightPanel={
        <div className="relative z-40 lg:h-full">
          <div id="event-details" className=" mt-12 lg:mt-0">
            <h2 className="text-5xl lg:text-6xl text-theme-purple-700 inline mr-2">
              Event Details
            </h2>
          </div>
          <div className="my-12 lg:my-0">
            <TimeLocSection eventData={eventIdQuery.data} />
          </div>
          <button
            onClick={() => history.push("/event/" + id + "/edit")}
            className="fixed z-50 lg:absolute bottom-6 right-6  lg:bottom-0 lg:right-0 bg-theme-red-300 px-3 py-1 rounded-xl text-white text-2xl"
          >
            Edit
          </button>
        </div>
      }
      TopPanel={
        <div className="relative w-full h-full">
          <div
            className="w-full bg-cover h-full"
            style={{
              backgroundImage: `url(${eventIdQuery?.data?.image})`,
            }}
          />
          <div
            className="absolute top-0 z-10 w-full h-full  bg-opacity-20"
            style={{
              background:
                "linear-gradient(180deg, rgba(0, 0, 0, 0.21) -36.43%, rgba(201, 201, 201, 0.05) 34.14%, rgba(255, 255, 255, 0) 106.2%)",
            }}
          ></div>
        </div>
      }
      BottomPanel={
        <BottomPanel data={eventIdQuery?.data} status={eventIdQuery?.status} />
      }
    />
  );
};

export default Event;
