import React from "react";

export const TYPES = {
  PLANE: "plane",
  HOUSE: "house",
  PLUS: "plus",
  MEAL: "meal",
  BED: "bed",
  AIRBNB: "airbnb",
  BURGER: "burger",
  EGG: "egg",
  DINNER_TABLE: "dinner-table",
  COCKTAIL: "cocktail",
  OTHER: "other",
  BOAT_WHEEL: "boat-wheel",
  CAR: "car",
  HOTEL_ROOM: "hotel-room",
  BUS: "bus",
  BOAT: "boat",
  UPLOAD: "upload",
  ARROW_RIGHT: "arrow-right",
  EDIT: "edit",
  DELETE: "trash-can",
};

function iconSwitch(icon, className) {
  switch (icon) {
    case TYPES.PLANE:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={"icon icon-tabler icon-tabler-plane " + className}
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M16 10h4a2 2 0 0 1 0 4h-4l-4 7h-3l2 -7h-4l-2 2h-3l2 -4l-2 -4h3l2 2h4l-2 -7h3z" />
        </svg>
      );
    case TYPES.HOUSE:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={"icon icon-tabler icon-tabler-home " + className}
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <polyline points="5 12 3 12 12 3 21 12 19 12" />
          <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
          <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
        </svg>
      );

    case TYPES.MEAL:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          height="44"
          width="44"
          strokeWidth="1.5"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={
            "icon icon-tabler icon-tabler-tools-kitchen-2 " + className
          }
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M19 3v12h-5c-.023 -3.681 .184 -7.406 5 -12zm0 12v6h-1v-3m-10 -14v17m-3 -17v3a3 3 0 1 0 6 0v-3" />
        </svg>
      );

    case TYPES.PLUS:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={"feather feather-plus " + className}
        >
          <line x1="12" y1="5" x2="12" y2="19"></line>
          <line x1="5" y1="12" x2="19" y2="12"></line>
        </svg>
      );
    case TYPES.BED:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={"icon icon-tabler icon-tabler-bed " + className}
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M3 7v11m0 -4h18m0 4v-8a2 2 0 0 0 -2 -2h-8v6" />
          <circle cx="7" cy="10" r="1" />
        </svg>
      );

    case TYPES.AIRBNB:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={"icon icon-tabler icon-tabler-brand-airbnb " + className}
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M12 10c-2 0 -3 1 -3 3c0 1.5 1.494 3.535 3 5.5c1 1 1.5 1.5 2.5 2s2.5 1 4.5 -.5s1.5 -3.5 .5 -6s-2.333 -5.5 -5 -9.5c-.834 -1 -1.5 -1.5 -2.503 -1.5c-1 0 -1.623 .45 -2.497 1.5c-2.667 4 -4 7 -5 9.5s-1.5 4.5 .5 6s3.5 1 4.5 .5s1.5 -1 2.5 -2c1.506 -1.965 3 -4 3 -5.5c0 -2 -1 -3 -3 -3z" />
        </svg>
      );

    case TYPES.BURGER:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          x="0px"
          y="0px"
          viewBox="0 0 32 32"
          stroke="currentColor"
          fill="currentColor"
          strokeWidth=".5"
          style={{ enableBackground: "new 0 0 32 32" }}
          xmlSpace="preserve"
          className={className}
        >
          <path d="M27.061,13.74C28,13.21,28,12,28,12c0-5-12-5-12-5S4,7,4,12c0,0,0,1.21,0.94,1.74C4,14.62,4,16,4,16  c0,1.12,0.42,1.82,0.94,2.26C4,18.79,4,20,4,20c0,0.74,0.28,1.21,0.62,1.5C4,22.09,4,23,4,23c0,3,3,3,3,3h18c0,0,3,0,3-3  c0,0,0-0.91-0.62-1.5C27.721,21.21,28,20.74,28,20c0-0.971-0.471-1.471-0.96-1.73C27.57,17.84,28,17.13,28,16  C28,14.88,27.58,14.18,27.061,13.74z M25,25H7c-0.33,0-2-0.1-2-1.99C5.01,22.55,5.19,22,6,22h20c0.811,0,0.99,0.55,1,1  C27,24.83,25.46,24.99,25,25z M26,21H6c-0.46-0.01-1-0.2-1-0.99C5.01,19.55,5.19,19,6,19h19.99c0.46,0.01,1.01,0.2,1.01,1  C27,20.811,26.45,20.99,26,21z M25,18H7c-0.33,0-2-0.1-2-2c0-0.33,0.1-2,2-2h18c0.33,0,2,0.1,2,2C27,17.83,25.46,17.99,25,18z   M26,13H6c-0.81,0-0.99-0.55-1-1c0-3.26,8.15-4,11-4c2.85,0,11,0.73,11,3.99C26.99,12.45,26.811,13,26,13z M12,10  c-0.552,0-1,0.448-1,1s0.448,1,1,1s1-0.448,1-1S12.552,10,12,10z M19,9c-0.553,0-1,0.448-1,1s0.447,1,1,1s1-0.448,1-1S19.553,9,19,9  z M23,10c-0.553,0-1,0.448-1,1s0.447,1,1,1s1-0.448,1-1S23.553,10,23,10z" />
        </svg>
      );

    case TYPES.EGG:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          x="0px"
          y="0px"
          viewBox="0 0 32 32"
          stroke="currentColor"
          fill="currentColor"
          strokeWidth=".5"
          style={{ enableBackground: "new 0 0 32 32" }}
          xmlSpace="preserve"
          className={className}
        >
          <path d="M23,11c-4.152-1.661-3-7-10-7C8,4,4,8.373,4,15s5.373,13,12,13c6.625,0,12-2,12-10C28,18,28,13,23,11z M16,27  c-5.86,0-11-5.607-11-12C5,9.206,8.364,5,13,5c3.67,0,4.691,1.566,5.875,3.38c0.847,1.298,1.806,2.769,3.754,3.548  C26.912,13.642,26.998,17.827,27,18C27,24.225,23.607,27,16,27z M15,11c-3.313,0-6,2.686-6,6s2.687,6,6,6c3.314,0,6-2.686,6-6  S18.314,11,15,11z M15,22c-2.757,0-5-2.243-5-5s2.243-5,5-5s5,2.243,5,5S17.757,22,15,22z" />
        </svg>
      );

    case TYPES.DINNER_TABLE:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          x="0px"
          y="0px"
          stroke="currentColor"
          fill="currentColor"
          strokeWidth=".5"
          viewBox="0 0 32 32"
          style={{ enableBackground: "new 0 0 32 32" }}
          xmlSpace="preserve"
          className={className}
        >
          <path d="M25,14H7v1h7v9h4v-9h7V14z M17,23h-2v-8h2V23z M5,17h7v7h-1v-6H5v6H4V8h1V17z M28,8v16h-1v-6h-6v6h-1v-7h7V8H28z" />
        </svg>
      );

    case TYPES.COCKTAIL:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          x="0px"
          y="0px"
          stroke="currentColor"
          fill="currentColor"
          strokeWidth=".5"
          viewBox="0 0 32 32"
          style={{ enableBackground: "new 0 0 32 32" }}
          xmlSpace="preserve"
          className={className}
        >
          <path d="M20.5,28H14v-7.5c0-0.5-0.108-0.977,0.95-2.034l8.757-8.759c0.286-0.286,0.371-0.716,0.217-1.09S23.404,8,23,8H4  C3.595,8,3.231,8.243,3.076,8.617c-0.155,0.374-0.069,0.804,0.217,1.09l8.739,8.741C13.074,19.49,13,20,13,20.5V28H6.5  C6.224,28,6,28.225,6,28.5S6.224,29,6.5,29h14c0.275,0,0.5-0.225,0.5-0.5S20.775,28,20.5,28z M4,9h19c0,0-1.4,1.401-3,3H7  C5.392,10.392,4,9,4,9z M11.5,16.5L8,13h11l-3.5,3.5c-0.791,0.791-1.357,1-2,1C12.877,17.5,12.291,17.291,11.5,16.5z M24.5,2.969  c-2.527,0-4.652,1.708-5.296,4.031h1.065c0.619-1.747,2.271-3.006,4.229-3.006c2.487,0,4.506,2.018,4.506,4.506  s-2.019,4.506-4.506,4.506c-0.761,0-1.465-0.206-2.093-0.538l-0.702,0.702c0.819,0.49,1.772,0.791,2.795,0.83  c3.397,0.131,5.5-2.495,5.5-5.532C29.999,5.431,27.537,2.969,24.5,2.969z" />
        </svg>
      );

    case TYPES.OTHER:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          x="0px"
          y="0px"
          stroke="currentColor"
          fill="currentColor"
          strokeWidth=".5"
          viewBox="0 0 32 32"
          style={{ enableBackground: "new 0 0 32 32" }}
          xmlSpace="preserve"
          preserveAspectRatio="none"
          className={className}
        >
          <path d="M22,10V4H4v18h6v6h18V10H22z M10,21H5V5h16v5H10V21z M15.29,16L11,20.3v-8.59L15.29,16z M11.71,11h8.58L16,15.29L11.71,11z   M16,16.71L20.29,21H11.7L16,16.71z M16.71,16L21,11.71v8.58L16.71,16z M27,27H11v-5h11V11h5V27z" />
        </svg>
      );
    case TYPES.BOAT_WHEEL:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          x="0px"
          y="0px"
          stroke="currentColor"
          fill="currentColor"
          strokeWidth=".5"
          viewBox="0 0 32 32"
          style={{ enableBackground: "new 0 0 32 32" }}
          xmlSpace="preserve"
          className={className}
        >
          <path d="M28.5,16h-3.51c-0.12-2.25-1.01-4.29-2.431-5.86l2.79-2.79c0.2-0.19,0.2-0.51,0-0.7c-0.189-0.2-0.51-0.2-0.699,0l-2.79,2.79  C20.29,8.02,18.25,7.13,16,7.01V3c0-0.28-0.22-0.5-0.5-0.5S15,2.72,15,3v4.01c-2.25,0.12-4.29,1.01-5.86,2.43L6.35,6.65  c-0.19-0.2-0.51-0.2-0.7,0c-0.2,0.19-0.2,0.51,0,0.7l2.79,2.79C7.02,11.71,6.13,13.75,6.01,16H2.5C2.22,16,2,16.22,2,16.5  S2.22,17,2.5,17h3.51c0.12,2.25,1.01,4.29,2.43,5.86l-2.79,2.79c-0.2,0.189-0.2,0.51,0,0.699C5.74,26.45,5.87,26.5,6,26.5  s0.26-0.05,0.35-0.15l2.79-2.79c1.57,1.421,3.61,2.311,5.86,2.431V29c0,0.28,0.22,0.5,0.5,0.5S16,29.28,16,29v-3.01  c2.25-0.12,4.29-1.01,5.86-2.431l2.79,2.79c0.09,0.101,0.22,0.15,0.35,0.15s0.26-0.05,0.35-0.15c0.2-0.189,0.2-0.51,0-0.699  l-2.79-2.79c1.421-1.57,2.311-3.61,2.431-5.86h3.51c0.28,0,0.5-0.22,0.5-0.5S28.78,16,28.5,16z M16,8.01  c1.97,0.12,3.77,0.91,5.15,2.14l-2.131,2.13c-0.829-0.7-1.869-1.15-3.02-1.25V8.01z M15,8.01v3.02c-1.15,0.1-2.19,0.55-3.02,1.25  l-2.13-2.13C11.23,8.92,13.03,8.13,15,8.01z M9.15,10.85l2.13,2.13c-0.7,0.83-1.15,1.87-1.25,3.02H7.01  C7.13,14.03,7.92,12.23,9.15,10.85z M7.01,17h3.02c0.1,1.15,0.55,2.19,1.25,3.02L9.15,22.15C7.92,20.77,7.13,18.97,7.01,17z   M15,24.99c-1.97-0.12-3.77-0.91-5.15-2.141l2.13-2.13c0.83,0.7,1.87,1.15,3.02,1.25V24.99z M15.5,21c-2.49,0-4.5-2.01-4.5-4.5  s2.01-4.5,4.5-4.5s4.5,2.01,4.5,4.5S17.99,21,15.5,21z M16,24.99V21.97c1.15-0.1,2.19-0.55,3.02-1.25l2.131,2.13  C19.77,24.08,17.97,24.87,16,24.99z M21.85,22.15l-2.13-2.131c0.7-0.829,1.15-1.869,1.25-3.02h3.021  C23.87,18.97,23.08,20.77,21.85,22.15z M20.97,16c-0.1-1.15-0.55-2.19-1.25-3.02l2.13-2.13c1.23,1.38,2.021,3.18,2.141,5.15H20.97z   M15.5,15c0.827,0,1.5,0.673,1.5,1.5S16.327,18,15.5,18S14,17.327,14,16.5S14.673,15,15.5,15 M15.5,14c-1.381,0-2.5,1.12-2.5,2.5  s1.119,2.5,2.5,2.5c1.38,0,2.5-1.12,2.5-2.5S16.88,14,15.5,14L15.5,14z" />
        </svg>
      );
    case TYPES.BOAT:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          x="0px"
          y="0px"
          stroke="currentColor"
          fill="currentColor"
          strokeWidth=".5"
          viewBox="0 0 32 32"
          style={{ enableBackground: "new 0 0 32 32" }}
          xmlSpace="preserve"
          className={className}
        >
          <path d="M30,16h-3v-4h-2V7h-3l-1,5h-1V7h-3l-1,5h-1V7h-3l-1,5H8l-3,4H2l2.85,7.61C4.38,23.85,3.82,24,3,24v1  c1.65,0,2.52-0.58,3.28-1.08C7.01,23.42,7.65,23,9,23c1.47,0,2.09,0.5,2.75,1.04C12.32,24.51,12.92,25,14,25s1.68-0.49,2.25-0.96  C16.91,23.5,17.53,23,19,23s2.09,0.5,2.75,1.04C22.32,24.51,22.92,25,24,25s1.68-0.49,2.25-0.96C26.91,23.5,27.53,23,29,23v-1  c-0.5,0-0.93,0.05-1.3,0.14L30,16z M22.82,8H24v1h-1.38L22.82,8z M22.42,10H24v2h-1.98L22.42,10z M17.82,8H19v1h-1.38L17.82,8z   M17.42,10H19v2h-1.98L17.42,10z M12.82,8H14v1h-1.38L12.82,8z M12.42,10H14v2h-1.98L12.42,10z M8.5,13H26v3H6.25L8.5,13z   M26.42,22.67c-0.31,0.19-0.57,0.4-0.8,0.59C25.1,23.69,24.72,24,24,24s-1.1-0.31-1.62-0.74C21.69,22.7,20.83,22,19,22  s-2.69,0.7-3.38,1.26C15.1,23.69,14.72,24,14,24s-1.1-0.31-1.62-0.74C11.69,22.7,10.83,22,9,22c-1.65,0-2.52,0.58-3.28,1.08L4.94,21  l22.1,0.02L26.42,22.67z M4.56,20l-1.12-3h25.12l-1.13,3H4.56z M29,25v1c-1.47,0-2.09,0.5-2.75,1.04C25.68,27.51,25.08,28,24,28  s-1.68-0.49-2.25-0.96C21.09,26.5,20.47,26,19,26s-2.09,0.5-2.75,1.04C15.68,27.51,15.08,28,14,28s-1.68-0.49-2.25-0.96  C11.09,26.5,10.47,26,9,26c-1.35,0-1.99,0.42-2.72,0.92C5.52,27.42,4.65,28,3,28v-1c1.35,0,1.99-0.42,2.72-0.92  C6.48,25.58,7.35,25,9,25c1.83,0,2.69,0.7,3.38,1.26C12.9,26.69,13.28,27,14,27s1.1-0.31,1.62-0.74C16.31,25.7,17.17,25,19,25  s2.69,0.7,3.38,1.26C22.9,26.69,23.28,27,24,27s1.1-0.31,1.62-0.74C26.31,25.7,27.17,25,29,25z" />
        </svg>
      );
    case TYPES.CAR:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          x="0px"
          y="0px"
          stroke="currentColor"
          fill="currentColor"
          strokeWidth=".5"
          viewBox="0 0 32 32"
          style={{ enableBackground: "new 0 0 32 32" }}
          xmlSpace="preserve"
          className={className}
        >
          <path d="M28,12h-1c0,0-0.53-0.2-2-2c-1.76-2.14-2-3-4-3s-6,0-6,0s-3,0-5,5c0,0-6,1-7,4v4c0,1,1,1,1,1h2.04c0.24,1.69,1.7,3,3.46,3  s3.22-1.31,3.46-3h7.08c0.24,1.69,1.7,3,3.46,3s3.22-1.31,3.46-3H28c0,0,2,0,2-2v-5C30,12,28,12,28,12z M9.5,23  C8.12,23,7,21.88,7,20.5S8.12,18,9.5,18s2.5,1.12,2.5,2.5S10.88,23,9.5,23z M23.5,23c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5  s2.5,1.12,2.5,2.5S24.88,23,23.5,23z M29,16h-2v1h2v2c0,0.81-0.55,0.99-1,1h-1.04c-0.24-1.69-1.7-3-3.46-3s-3.22,1.31-3.46,3h-7.08  c-0.24-1.69-1.7-3-3.46-3s-3.22,1.31-3.46,3H5c0,0-1,0-1-1v-2.82c0.74-1.68,3.73-2.84,6.14-3.189l0.57-0.08l0.22-0.54  C12.62,8.15,14.92,8,15,8h6c1.16,0,1.35,0.25,2.41,1.6c0.24,0.31,0.5,0.65,0.82,1.03c1.069,1.31,1.899,2.11,2.409,2.3L26.81,13  h1.181c0.46,0.01,1.01,0.19,1.01,1V16z M15,9c0,0-3,1-3,5h12c0-2-3-5-3-5H15z M17,13h-3.92c0.34-2.04,1.69-2.81,2.11-3H17V13z   M18,13v-3h2.58c0.78,0.84,1.689,2.03,2.14,3H18z" />
        </svg>
      );

    case TYPES.HOTEL_ROOM:
      return (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          stroke="currentColor"
          fill="currentColor"
          strokeWidth=".5"
          viewBox="0 0 32 32"
          style={{ enableBackground: "new 0 0 32 32" }}
          xmlSpace="preserve"
          className={className}
        >
          <path
            d="M27,4h-6c-2,0-2,2-2,2v7c0,2,2,2,2,2h6c2,0,2-2,2-2V6C29,4,27,4,27,4z M28,12.99C27.99,13.45,27.81,14,27,14h-5.99
      C20.55,13.99,20,13.81,20,13V6.01C20.01,5.55,20.19,5,21,5h5.99C27.45,5.01,28,5.2,28,6V12.99z M25,9h-2V7h-1v5h1v-2h2v2h1V7h-1V9z
       M20,24v-7H6c-3,0-3,3-3,3v9h26v-5h-4.5H20z M4,20c0-0.33,0.1-2,2-2h13v6H4V20z M28,28H4v-3h24V28z M28,20.5
      c0-1.93-1.57-3.5-3.5-3.5S21,18.57,21,20.5s1.57,3.5,3.5,3.5S28,22.43,28,20.5z M24.5,23c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5
      s2.5,1.12,2.5,2.5S25.88,23,24.5,23z"
          />
        </svg>
      );

    case TYPES.BUS:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          x="0px"
          y="0px"
          stroke="currentColor"
          fill="currentColor"
          strokeWidth=".5"
          viewBox="0 0 32 32"
          style={{ enableBackground: "new 0 0 32 32" }}
          xmlSpace="preserve"
          className={className}
        >
          <path d="M26,8H8c0,0-5,7-5,9s0,2,0,2s0,3,3,3h1.18c0.4,1.17,1.51,2,2.82,2s2.42-0.83,2.82-2h6.36c0.4,1.17,1.512,2,2.82,2  s2.42-0.83,2.82-2H26c0,0,3,0,3-3v-8C29,11,29,8,26,8z M18,9h5v5h-5V9z M12,9h5v5h-5V9z M8.52,9H11v5H5.32  C6.18,12.48,7.36,10.65,8.52,9z M11.73,22c-0.35,0.6-0.99,1-1.73,1s-1.38-0.4-1.73-1C8.1,21.71,8,21.359,8,21c0-1.1,0.9-2,2-2  s2,0.9,2,2C12,21.359,11.9,21.71,11.73,22z M23.73,22c-0.352,0.6-0.99,1-1.73,1s-1.38-0.4-1.73-1C20.1,21.71,20,21.359,20,21  c0-1.1,0.9-2,2-2s2,0.9,2,2C24,21.359,23.9,21.71,23.73,22z M28,19c0,1.83-1.54,1.99-2,2h-1c0-1.66-1.34-3-3-3s-3,1.34-3,3h-6  c0-1.66-1.34-3-3-3s-3,1.34-3,3H6c-1.83,0-1.99-1.54-2-2v-2c0-0.359,0.29-1.08,0.77-2H28V19z M28,14h-4V9h2c1.83,0,1.99,1.54,2,2V14  z" />
        </svg>
      );

    case TYPES.UPLOAD:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          x="0px"
          y="0px"
          stroke="currentColor"
          fill="currentColor"
          strokeWidth=".5"
          viewBox="0 0 32 32"
          style={{ enableBackground: "new 0 0 32 32" }}
          xmlSpace="preserve"
          className={className}
        >
          <path d="M15.5,15.905l4.157,4.157L18.95,20.77L16,17.82V30h-1V17.82l-2.95,2.949l-0.707-0.707L15.5,15.905z M27.619,14.107  c-0.35-0.091-0.949-0.219-1.622-0.356C25.98,11.129,23.845,9,21.219,9c-0.467,0-0.912,0.081-1.338,0.207  C19.266,5.687,16.194,3,12.5,3C8.364,3,5,6.364,5,10.5c0,0.572,0.07,1.128,0.192,1.664C2.79,12.753,1,14.919,1,17.5  C1,20.532,3.467,23,6.5,23H11v-1H6.5C4.015,22,2,19.985,2,17.5S4.015,13,6.5,13C6.179,12.23,6,11.386,6,10.5C6,6.91,8.91,4,12.5,4  S19,6.91,19,10.5l-0.013,0.242C19.614,10.281,20.381,10,21.219,10C23.307,10,25,11.693,25,13.781c0,0.266-0.028,0.524-0.081,0.774  c0.992,0.195,1.971,0.396,2.448,0.52C28.882,15.469,30,16.845,30,18.481C30,20.425,28.425,22,26.481,22H20v1h6.481  C28.974,23,31,20.974,31,18.481C31,16.422,29.609,14.624,27.619,14.107z" />
        </svg>
      );

    case TYPES.ARROW_RIGHT:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          x="0px"
          y="0px"
          stroke="currentColor"
          fill="currentColor"
          strokeWidth=".5"
          viewBox="0 0 32 32"
          style={{ enableBackground: "new 0 0 32 32" }}
          xmlSpace="preserve"
          preserveAspectRatio="none"
          className={className}
        >
          <path d="M15.5,3C8.596,3,3,8.597,3,15.5S8.596,28,15.5,28C22.403,28,28,22.403,28,15.5S22.403,3,15.5,3z M15.5,26.995  c-6.349,0-11.495-5.146-11.495-11.495S9.151,4.005,15.5,4.005S26.995,9.151,26.995,15.5S21.849,26.995,15.5,26.995z M12.948,7.509  l7.984,7.975l-7.912,8.03l-0.817-0.706l7.314-7.322l-7.275-7.269L12.948,7.509z" />
        </svg>
      );

    case TYPES.EDIT:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          x="0px"
          y="0px"
          stroke="currentColor"
          fill="currentColor"
          strokeWidth=".5"
          viewBox="0 0 32 32"
          style={{ enableBackground: "new 0 0 32 32" }}
          xmlSpace="preserve"
          preserveAspectRatio="none"
          className={className}
        >
          <path d="M27.046,4.933c-1.268-1.268-3.329-1.268-4.597,0L6.676,20.722c-0.09,0.09-0.162,0.196-0.212,0.313l-2.512,5.693  c-0.161,0.376-0.077,0.812,0.212,1.101s0.725,0.373,1.101,0.213l5.693-2.512c0.117-0.051,0.223-0.122,0.313-0.213L27.046,9.529  C28.313,8.262,28.313,6.2,27.046,4.933z M7.127,26.127l-1.26-1.261l1.314-2.979l2.925,2.925L7.127,26.127z M10.941,24.234  l-3.182-3.182c1.719-1.721,8.92-8.93,12.849-12.862l3.182,3.182C19.861,15.305,12.66,22.514,10.941,24.234z M24.497,10.665  l-3.183-3.182c0.242-0.242,0.466-0.466,0.668-0.668l3.182,3.182C24.963,10.198,24.738,10.423,24.497,10.665z M26.339,8.821  c-0.07,0.07-0.233,0.234-0.468,0.468l-3.182-3.182c0.233-0.234,0.396-0.397,0.467-0.468c0.879-0.879,2.304-0.879,3.183,0  C27.217,6.519,27.217,7.943,26.339,8.821z" />
        </svg>
      );
    case TYPES.DELETE:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          x="0px"
          y="0px"
          stroke="currentColor"
          fill="currentColor"
          strokeWidth=".5"
          viewBox="0 0 32 32"
          style={{ enableBackground: "new 0 0 32 32" }}
          xmlSpace="preserve"
          className={className}
        >
          <path d="M11,25h-1V11h1V25z M21,11h-1v14h1V11z M16,11h-1v14h1V11z M5,8h21l-1.503,19.083C24.453,27.602,24.02,28,23.5,28h-16  c-0.52,0-0.953-0.398-0.997-0.917L5,8z M24.906,9H6.094L7.5,27h16L24.906,9z M26,6v1H5V6h5V4.5C10,3.672,10.671,3,11.5,3h8  C20.328,3,21,3.672,21,4.5V6H26z M20,4.5C20,4.224,19.776,4,19.5,4h-8C11.224,4,11,4.224,11,4.5V6h9V4.5z" />
        </svg>
      );

    default:
      return null;
  }
}

const Icon = ({ icon, className }) => {
  return <>{iconSwitch(icon, className)}</>;
};

export default Icon;
