import React from "react";

const ErrorAlert = ({ errorMessage }) => {
  return (
    <div className="w-full flex justify-center text-center left-0 items-center font-medium py-1 px-2 rounded-md text-red-700 bg-red-100 border border-red-300 ">
      <div slot="avatar">
        <svg
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          className="feather feather-alert-octagon w-5 h-5 mx-2"
        >
          <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
          <line x1="12" y1="8" x2="12" y2="12"></line>
          <line x1="12" y1="16" x2="12.01" y2="16"></line>
        </svg>
      </div>
      <p className="text-sm font-normal flex-initial">{errorMessage}</p>
    </div>
  );
};

export default ErrorAlert;
