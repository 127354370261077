import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import moment from "moment";
import TripCard from "./components/TripCard";
import { getAllTripsForUser } from "api/Queries";
import { TYPES } from "modules/common/icons";
import Icons from "modules/common/icons";
import kitten from "./images/kitty.svg";
import CreateTripModal from "./components/CreateTripModal";
import PagePadding from "modules/navbar/components/PagePadding";

const AllTrips = () => {
  const [pastSelected, setPastSelected] = useState(false);
  const [displayedTrips, setDisplayedTrips] = useState([]);
  const [showCreateTripModal, setShowCreateTripModal] = useState(false);

  const { data } = useQuery("trips", getAllTripsForUser);
  useEffect(() => {
    const now = new Date();
    if (data && data.data) {
      setDisplayedTrips(
        data.data.filter(
          (trip) =>
            (!pastSelected && moment(trip.startDate).isAfter(now)) ||
            (pastSelected && moment(trip.startDate).isBefore(now))
        )
      );
    }
  }, [pastSelected, data]);

  return (
    <>
      <PagePadding/>
      <div className="max-w-7xl w-full px-4 pt-4 sm:px-6 lg:px-8 flex flex-col mx-auto">
        <div className="text-5xl font-light text-gray-900 pb-2">Trips</div>
        <div className="flex flex-row gap-x-6 pb-1 border-b border-gray-300 text-gray-600 font-light">
          <button
            className={
              "-mb-1 p-2 lg:hover:bg-gray-100 focus:outline-none " +
              (!pastSelected && "border-b-2 border-theme-purple-500")
            }
            onClick={() => setPastSelected(false)}
          >
            Upcoming
          </button>
          <button
            className={
              "-mb-1 p-2 lg:hover:bg-gray-100 focus:outline-none " +
              (pastSelected && "border-b-2 border-theme-purple-500")
            }
            onClick={() => setPastSelected(true)}
          >
            Past
          </button>
        </div>
        {data &&
          displayedTrips &&
          (displayedTrips.length === 0 ? (
            <div className="flex flex-col justify-items-center pt-4">
              <img
                src={kitten}
                alt="..."
                className="h-72 sm:h-80 md:h-96"
              ></img>
              <div className="mx-auto font-light pt-4 text-center">
                There are no Trips to display. Try creating or joining one now!
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 py-8 justify-start gap-8">
              {displayedTrips.map((trip) => (
                <TripCard
                  key={trip.title + "-" + trip.startDate + "-" + trip.endDate}
                  tripTitle={trip.title}
                  image={trip.image}
                  startDate={trip.startDate}
                  endDate={trip.endDate}
                  attendeesImages={trip.attendees}
                />
              ))}
            </div>
          ))}

        <CreateTripModal
          setShowCreateTripModal={setShowCreateTripModal}
          showCreateTripModal={showCreateTripModal}
        />

        {!showCreateTripModal && (
          <button
            className="bg-theme-red-300 fixed right-5 md:right-6 bottom-3 h-14 w-14 rounded-full flex justify-center items-center outline-none z-50 focus:outline-none focus:shadow-none"
            onClick={() => setShowCreateTripModal(true)}
          >
            <Icons icon={TYPES.PLUS} className="h-11 w-11 text-white" />
          </button>
        )}
      </div>
    </>
  );
};

export default AllTrips;
