import React, { useState, useRef } from "react";
import { useOnClickOutside } from "hooks/hooks";

const CostCurrencyField = ({
  cost,
  setCost,
  currency,
  setCurrency,
  title,
  placeholder,
  className,
  maxLength,
  required,
  displayAsterick,
  uniqueKey,
}) => {
  const fieldRef = useRef();
  const [isFocused, setIsFocused] = useState(false);

  useOnClickOutside(fieldRef, () => {
    setIsFocused(false);
  });

  return (
    <div
      className={className}
      ref={fieldRef}
      onClick={() => setIsFocused(true)}
      key={uniqueKey + "-cost-currency"}
    >
      {displayAsterick && (
        <div className="inline text-theme-red-400 font-medium mg:text-lg">
          {"* "}
        </div>
      )}
      {title && (
        <label
          htmlFor={uniqueKey + "-cost"}
          className="inline font-medium text-gray-700 md:text-lg"
        >
          {title}
        </label>
      )}
      <div
        className={
          (isFocused
            ? "border-theme-purple-400 border-2"
            : "border border-gray-300") +
          " mt-1 h-12 overflow-hidden relative rounded-md shadow-sm  bg-white"
        }
      >
        <input
          required={required}
          id={uniqueKey + "-cost"}
          name={uniqueKey + "-cost"}
          type="number"
          autoComplete="off"
          value={cost || ""}
          maxLength={maxLength}
          onChange={(e) => setCost(e.target.value)}
          className="sm:text-base absolute w-full border-transparent bg-transparent focus:border-transparent focus:ring-transparent inset-y-0 left-0 "
          placeholder={placeholder}
        />
        <div className="absolute inset-y-0 right-0 flex items-center">
          <label htmlFor={title + "-currency"} className="sr-only">
            Currency
          </label>
          <select
            required={required}
            value={currency ? currency : "USD"}
            id={uniqueKey + "-currency"}
            name={uniqueKey + "-currency"}
            onChange={(e) => setCurrency(e.target.value)}
            className="border-transparent focus:border-transparent focus:ring-transparent inset-y-0 block sm:text-base sm:max-w-xs "
          >
            <option value="USD">USD</option>
          </select>
        </div>
      </div>
    </div>
  );
};

CostCurrencyField.defaultProps = {
  title: "Cost",
  placeholder: "0.00",
  maxLength: 10,
  required: false,
  displayAsterick: false,
};

export default CostCurrencyField;
