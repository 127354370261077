import React, {
  useState,
  useReducer,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { putEvent, getEvent } from "api/Queries";

import PointInTime from "modules/eventedit/components/PointInTime";
import PricingSection from "./components/PricingSection";
import BasicInfoSection from "./components/BasicInfoSection";
import SingleImageUpload from "modules/common/components/SingleImageUpload";
import PagePadding from "modules/navbar/components/PagePadding";
import Slider from "modules/common/components/Slider";
import Icon, { TYPES } from "modules/common/icons";
import moment from "moment";
// import InternetSection from "./components/InternetSection";

// Reducer Actions
export const ACTIONS = {
  INIT_EVENT: "init-event",
  UPDATE_EVENT_INPUT_FIELD: "update-event-form-field",
  UPDATE_EVENT_BOOLEAN_FIELD: "update-event-boolean-field",
  ADD_POINT_IN_TIME: "add-point-in-time",
  DELETE_POINT_IN_TIME: "delete-point-in-time",
  UPDATE_POINT_IN_TIME: "update-point-in-time",
};

// Events component
const EventEdit = () => {
  const { id: eventId } = useParams();
  const eventIdQuery = useQuery(eventId, () => getEvent(eventId));
  // Reducer for Event Form
  const [eventForm, eventFormDispatch] = useReducer(eventFormReducer, {});

  function eventFormReducer(eventForm, action) {
    switch (action.type) {
      case ACTIONS.UPDATE_EVENT_INPUT_FIELD:
        return { ...eventForm, [action.payload.attr]: action.payload.value };
      case ACTIONS.UPDATE_EVENT_BOOLEAN_FIELD:
        return {
          ...eventForm,
          [action.payload.attr]: !eventForm[action.payload.attr],
        };

      case ACTIONS.INIT_EVENT:
        const event = action.payload.event;
        return {
          ...event,
        };
      case ACTIONS.UPDATE_POINT_IN_TIME:
        return {
          ...eventForm,
          pointsInTime: eventForm.pointsInTime.map((pointInTime, index) => {
            if (index === action.payload.index)
              return {
                ...pointInTime,
                [action.payload.attr]: action.payload.value,
              };
            return pointInTime;
          }),
        };
      case ACTIONS.ADD_POINT_IN_TIME:
        return {
          ...eventForm,
          pointsInTime: [
            ...eventForm.pointsInTime,
            {
              temp_id: Date.now(),
              dateTime: action.payload.dateTime,
              timezone: action.payload.timezone,
            },
          ],
        };
      case ACTIONS.DELETE_POINT_IN_TIME:
        console.log(action.payload._id);
        console.log(action.payload.temp_id);
        console.log(eventForm.pointsInTime);
        return {
          ...eventForm,
          pointsInTime: eventForm.pointsInTime.filter((pointInTime) => {
            if (pointInTime._id && pointInTime._id !== action.payload.id)
              return pointInTime;
            if (
              pointInTime.temp_id &&
              pointInTime.temp_id !== action.payload.temp_id
            )
              return pointInTime;
            return null;
          }),
        };

      default:
        return eventForm;
    }
  }

  // state
  const [eventImageUrl, setEventImageUrl] = useState();
  const [loadingEventImage, setLoadingEventImage] = useState(false);
  const [eventCreateError, setEventCreateError] = useState();
  // eslint-disable-next-line
  const [imageError, setImageError] = useState();
  const [sliderPosition, setSliderPosition] = useState(0);
  const [panels, setPanels] = useState([]);
  const prevPanelCount = useRef(0);

  // initiate history
  const history = useHistory();

  // Mutations
  const eventMutation = useMutation(putEvent, {
    onError: async (error) => {
      console.log(error.response.data.errorMessage);
      setEventCreateError(error.response.data.errorMessage);
    },
    onSuccess: async () => {
      history.goBack();
    },
  });

  // function to run eventFormDispatch

  const handleEventUpdate = useCallback(
    (attr, value) => {
      eventFormDispatch({
        type: ACTIONS.UPDATE_EVENT_INPUT_FIELD,
        payload: { attr: attr, value: value },
      });
    },
    [eventFormDispatch]
  );

  // Defaults Time Zone and Date and creates new Point in Time Record
  const handlePointInTimeAdd = () => {
    const date = new Date();
    const defaultTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const defaultDate = moment.utc(date).local().format();

    eventFormDispatch({
      type: ACTIONS.ADD_POINT_IN_TIME,
      payload: { dateTime: defaultDate, timezone: defaultTZ },
    });
  };

  // UseEffect to initialize the EventForm State from the eventIdQuery
  useEffect(() => {
    if (
      Object.keys(eventForm).length === 0 &&
      eventIdQuery.status === "success"
    ) {
      eventFormDispatch({
        type: ACTIONS.INIT_EVENT,
        payload: { event: eventIdQuery.data },
      });
    }
  }, [eventIdQuery, eventForm]);

  // Generates Point in time Components when more panels are created
  useEffect(() => {
    if (!eventForm?.pointsInTime) return;
    setPanels(
      eventForm.pointsInTime.map((pointInTime, index) => {
        return (
          <PointInTime
            key={pointInTime.id}
            pointInTime={pointInTime}
            index={index}
            dispatch={eventFormDispatch}
            panels={panels.length}
          />
        );
      })
    );
  }, [eventForm.pointsInTime, panels.length]);

  // Handles the Sliders Start Position and Prevents from Sliding too far
  useEffect(() => {
    if (prevPanelCount.current === 0) {
      prevPanelCount.current = panels.length;
      return;
    }
    if (sliderPosition > panels.length - 1) {
      setSliderPosition(panels.length - 1);
    }
    if (
      prevPanelCount.current === panels.length ||
      prevPanelCount.current > panels.length
    ) {
      prevPanelCount.current = panels.length;
      return;
    }
    prevPanelCount.current = panels.length;
    setSliderPosition(panels.length - 1);
  }, [panels.length, sliderPosition]);

  // Function to handle create event click!
  async function updateEvent(e) {
    e.preventDefault();

    const eventUpdateBody = {
      ...eventForm,
      image: eventImageUrl,
    };

    try {
      eventMutation.mutate({ id: eventId, updatedEvent: eventUpdateBody });
    } catch (error) {
      console.log("Event Failed to create");
    }
  }

  return (
    <>
      <PagePadding />
      <form
        className="pb-6 lg:pt-16"
        onSubmit={updateEvent}
        style={{
          background:
            "linear-gradient(90deg, #FFFBFC -3.71%, rgba(255, 255, 255, 1) 105.44%)",

          maxHeight: "90%",
        }}
      >
        {eventIdQuery.status === "success" && (
          <div className="container mx-auto px-8 sm:px-8 lg:px-72 space-y-6">
            <div className="space-y-6 grid grid-cols-4 gap-2">
              <div className="col-span-4 grid grid-cols-4 gap-4">
                {eventCreateError && (
                  <div className="col-span-4">{eventCreateError}</div>
                )}
              </div>
              <h1 className="col-span-4 font-medium text-4xl">Edit Event</h1>

              <SingleImageUpload
                defaultImage={eventIdQuery.data.image}
                imageUrlState={eventImageUrl}
                setImageUrlState={setEventImageUrl}
                folder="events"
                loading={loadingEventImage}
                setLoading={setLoadingEventImage}
                setError={setImageError}
              />

              <div className="flex items-center">
                <label htmlFor="eventCreatePublic">
                  <span className="block text-sm font-medium text-gray-700 mr-3">
                    Public
                  </span>
                </label>
                <button
                  id="eventCreatePublic"
                  name="eventCreatePublic"
                  type="button"
                  aria-pressed="false"
                  defaultValue={eventIdQuery.data.public}
                  onClick={(e) =>
                    eventFormDispatch({
                      type: ACTIONS.UPDATE_EVENT_BOOLEAN_FIELD,
                      payload: { attr: "public", value: e.target.value },
                    })
                  }
                  className={
                    (eventForm.public ? "bg-indigo-600" : "bg-gray-200") +
                    " relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  }
                >
                  <span className="sr-only">Use setting</span>

                  <span
                    aria-hidden="true"
                    className={
                      (eventForm.public ? "translate-x-5" : "translate-x-0") +
                      " pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                    }
                  />
                </button>
              </div>

              <BasicInfoSection
                handleEventUpdate={handleEventUpdate}
                eventForm={eventForm}
              />

              <PricingSection
                handleEventUpdate={handleEventUpdate}
                eventForm={eventForm}
              />
              <div className="col-span-4 flex items-center justify-between">
                <h3 className="col-span-4  text-2xl">Time & Location</h3>{" "}
                {(eventIdQuery.data.type === "transit" ||
                  eventIdQuery.data.type === "lodging") && (
                  <div className="text-theme-purple-600 font-light right-0 top-1">
                    {sliderPosition + 1} of {panels.length}
                  </div>
                )}
              </div>

              {eventForm.pointsInTime && (
                <div
                  className="relative col-span-4 w-full  "
                  style={{ height: "24rem" }}
                >
                  {(eventIdQuery.data.type === "transit" ||
                    eventIdQuery.data.type === "lodging") && (
                    <button
                      onClick={() => {
                        handlePointInTimeAdd();
                      }}
                      type="button"
                      className=" absolute z-50 right-0 bottom-4 border-2 border-theme-red-300 rounded-full text-theme-red-300 border-theme-red-300 flex items-center justify-center h-8 w-8 focus:outline-none"
                    >
                      <Icon
                        icon={TYPES.PLUS}
                        className="text-theme-red-300 h-6"
                      />
                    </button>
                  )}
                  <Slider
                    position={sliderPosition}
                    setPosition={setSliderPosition}
                    panels={panels}
                  />
                </div>
              )}
            </div>

            <div className="col-span-4 border-b-2 w-full"></div>

            <button
              type="submit"
              className="bg-theme-red-300 border rounded-2xl border-transparent py-4 shadow-sm w-full inline-flex justify-center items-center text-lg font-medium text-white hover:bg-indigo-700 focus:outline-none active:outline-none disabled:opacity-50"
            >
              Update Event
            </button>
            <button
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                history.goBack();
              }}
              className="border-theme-red-300 border-2 text-theme-red-300 rounded-2xl border-transparent py-4 shadow-sm w-full inline-flex justify-center items-center text-lg font-medium  hover:bg-indigo-700 focus:outline-none active:outline-none disabled:opacity-50"
            >
              Cancel
            </button>
          </div>
        )}
      </form>
    </>
  );
};

export default EventEdit;
