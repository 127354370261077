import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import EventTypeBubble from "./components/EventTypeBubble";
import { TYPES } from "modules/common/icons";
import Icons from "modules/common/icons";
import { eventSubTypeInfo } from "modules/common/dicts/eventSubTypeInfo";

const AddItemButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [typeChosen, setTypeChosen] = useState("");

  const eventTypes = Object.keys(eventSubTypeInfo).map((key) => {
    return { type: key, icon: eventSubTypeInfo[key].icon };
  });

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  const buttonVariants = {
    initial: { rotate: 0 },
    animate: { rotate: 45 },
  };

  const containerVariants = {
    initial: { opacity: 0 },
    open: {
      opacity: 1,
    },
    close: {
      opacity: 0,
      transition: {
        delay: 0.5,
      },
    },
  };

  const eventTypeVariants = {
    initial: {},
    open: {
      transition: {
        staggerChildren: 0.15,
        staggerDirection: -1,
      },
    },
    close: {
      transition: {
        delay: 0.3,
        staggerChildren: 0.15,
      },
    },
  };

  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className={
              "bg-black bg-opacity-50 h-screen w-full fixed top-0 right-0 z-50"
            }
            onClick={() => setIsOpen(false)}
            variants={containerVariants}
            initial={false}
            animate={isOpen ? "open" : "close"}
          >
            <motion.ul
              className="fixed right-5 md:right-6 bottom-2"
              variants={eventTypeVariants}
              initial="close"
              animate="open"
              exit="close"
            >
              {eventTypes.map((eventType) => (
                <EventTypeBubble
                  icon={eventType.icon}
                  type={eventType.type}
                  typeChosen={typeChosen}
                  setTypeChosen={setTypeChosen}
                  key={eventType.type}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                ></EventTypeBubble>
              ))}
            </motion.ul>
          </motion.div>
        )}
      </AnimatePresence>

      <motion.button
        className="bg-theme-red-300 fixed right-5 md:right-6 bottom-3 h-14 w-14 rounded-full flex justify-center items-center outline-none z-50 focus:outline-none focus:shadow-none"
        onClick={() => setIsOpen((prevState) => !prevState)}
        whileHover={{ scale: 1.1 }}
        variants={buttonVariants}
        initial="initial"
        animate={isOpen ? "animate" : "initial"}
        exit="exit"
      >
        <Icons icon={TYPES.PLUS} className="h-11 w-11 text-white" />
      </motion.button>
    </>
  );
};

export default AddItemButton;
