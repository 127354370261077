import React, { useState, useRef, useReducer } from "react";

import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { uploadImage, postBlog, getEvents } from "api/Queries";
import ClipLoader from "react-spinners/ClipLoader";
import MDEditor from "@uiw/react-md-editor";
import BlogSectionCreate from "modules/blogcreate/components/BlogSectionCreate";

// Reducer Actions
export const ACTIONS = {
  UPDATE_BLOG_INPUT_FIELD: "update-blog-input-field",
  UPDATE_BLOG_BOOLEAN_FIELD: "update-blog-boolean-field",
  ADD_BLOG_SECTION: "add-blog-section",
  UPDATE_BLOG_SECTION: "update-blog-section",
};

// Blogs component
const BlogCreate = ({ displayName }) => {
  // Reducer for Blog Sections
  const [blogForm, blogFormDispatch] = useReducer(blogFormReducer, {
    public: false,
    blogSections: [],
  });

  function blogFormReducer(blogForm, action) {
    switch (action.type) {
      case ACTIONS.UPDATE_BLOG_INPUT_FIELD:
        return { ...blogForm, [action.payload.attr]: action.payload.value };
      case ACTIONS.UPDATE_BLOG_BOOLEAN_FIELD:
        return {
          ...blogForm,
          [action.payload.attr]: !blogForm[action.payload.attr],
        };
      case ACTIONS.ADD_BLOG_SECTION:
        return {
          ...blogForm,
          blogSections: [...blogForm.blogSections, newBlogSection()],
        };
      case ACTIONS.UPDATE_BLOG_SECTION:
        return {
          ...blogForm,
          blogSections: blogForm.blogSections.map((section) => {
            if (section.id === action.payload.id)
              return {
                ...section,
                [action.payload.attr]: action.payload.value,
              };
            return section;
          }),
        };
      default:
        return blogForm;
    }
  }

  function newBlogSection() {
    return { id: Date.now() };
  }

  // initiate eventQuery
  const eventQuery = useQuery("events", () => getEvents(displayName));

  // state
  const [blogCreateImage, setBlogCreateImage] = useState("");
  const [blogCreateError, setBlogCreateError] = useState("");

  // initiate history
  const history = useHistory();

  // Needed for File Input
  const inputFile = useRef(null);

  // Mutations
  const blogMutation = useMutation(postBlog, {
    onError: async (error) => {
      setBlogCreateError(error.response.data.errorMessage);
    },
    onSuccess: async () => {
      history.goBack();
    },
  });

  const imageMutation = useMutation(uploadImage, {
    onError: async (error) => {
      console.log(error);

      setBlogCreateError(error.response.data.errorMessage);
    },
    onSuccess: async (data) => {
      setBlogCreateImage(data.image_url);
    },
  });

  // function to run blogFormDispatch for input fields
  const handleBlogUpdate = (attr, value) => {
    blogFormDispatch({
      type: ACTIONS.UPDATE_BLOG_INPUT_FIELD,
      payload: { attr: attr, value: value },
    });
  };

  // function to handle Create Blog click
  async function createBlog(e) {
    e.preventDefault();
    const blogCreateBody = {
      title: blogForm.title,
      image: blogCreateImage,
      description: blogForm.description,
      public: blogForm.public,
      blogSections: blogForm.blogSections,
    };
    console.log(blogCreateBody);
    // On Create Blog fire mutation to createBlog
    try {
      blogMutation.mutate(blogCreateBody);
    } catch (error) {
      console.log("Blog Failed to create");
    }
  }

  function handleBlogSectionClick() {
    blogFormDispatch({
      type: ACTIONS.ADD_BLOG_SECTION,
    });
  }

  async function submitImage(e) {
    e.preventDefault();
    const newImage = e.target.files[0];
    const bodyFormData = new FormData();

    bodyFormData.append("image", newImage);
    try {
      imageMutation.mutate(bodyFormData);
    } catch (error) {
      console.log(error);
      setBlogCreateError(error.response.data.errorMessage);
    }
  }

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  return (
    <div className="pb-24 pt-24">
      <div className="container mx-auto px-8 sm:px-8 lg:px-72 pt-8 space-y-6">
        <div className="space-y-6 grid grid-cols-4 ">
          <div className="col-span-4 grid grid-cols-4 gap-4">
            {blogCreateError && (
              <div className="col-span-4">{blogCreateError}</div>
            )}
          </div>

          <div className="col-span-4">
            <label
              htmlFor="blogCreateTitle"
              className="block text-sm font-medium text-gray-700"
            >
              Title
            </label>
            <input
              id="blogCreateTitle"
              name="blogCreateTitle"
              autoComplete="off"
              onChange={(e) => handleBlogUpdate("title", e.target.value)}
              className="mt-1 block w-full border border-gray-300 max-w-md rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            ></input>
          </div>

          <div className="col-span-4">
            <label
              htmlFor="blogCreateDescription"
              className="block mb-1 text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Description
            </label>
            <MDEditor
              height={200}
              value={blogForm.description ? blogForm.description : ""}
              preview="edit"
              visiableDragbar={false}
              onChange={(value) => handleBlogUpdate("description", value)}
              className="overflow-hidden"
            />
          </div>

          <input
            id="imageInput"
            type="file"
            accept="image/*"
            ref={inputFile}
            onChange={(e) => submitImage(e)}
            style={{ display: "none" }}
          />
          <div className="col-span-4 grid grid-cols-4 gap-4">
            <button
              type="button"
              id="openImageUpload"
              onClick={onButtonClick}
              className="max-w-xs col-span-2 inline-flex justify-center items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Upload Image
            </button>
            {imageMutation.isLoading && (
              <div className="col-span-1 flex items-center">
                <ClipLoader color="#4450b5" />
              </div>
            )}

            {imageMutation.isSuccess && (
              <div className="col-span-1 flex items-center">
                {blogCreateImage && (
                  <img
                    src={blogCreateImage.toString()}
                    className="h-10"
                    alt="thing"
                  ></img>
                )}
              </div>
            )}
          </div>

          <div className="flex items-center col-span-4">
            <label htmlFor="isBlogCreatePublic">
              <span className="block text-sm font-medium text-gray-700 mr-3">
                Public
              </span>
            </label>
            <button
              id="isBlogCreatePublic"
              name="isBlogCreatePublic"
              type="button"
              aria-pressed="false"
              onClick={(e) =>
                blogFormDispatch({
                  type: ACTIONS.UPDATE_BLOG_BOOLEAN_FIELD,
                  payload: { attr: "public", value: e.target.value },
                })
              }
              className={
                (blogForm.public ? "bg-indigo-600" : "bg-gray-200") +
                " relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              }
            >
              <span className="sr-only">Use setting</span>

              <span
                aria-hidden="true"
                className={
                  (blogForm.public ? "translate-x-5" : "translate-x-0") +
                  " pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                }
              />
            </button>
          </div>
          <div className="col-span-4 grid grid-cols-4">
            <button
              onClick={(e) => handleBlogSectionClick(e)}
              className={
                "bg-indigo-600 col-span-2 bottom-0 border rounded border-transparent h-12 shadow-sm w-full inline-flex justify-center items-center text-lg font-medium text-white hover:bg-indigo-700 focus:outline-none active:outline-none disabled:opacity-50"
              }
            >
              Add Blog Section
            </button>
          </div>
        </div>
        {eventQuery.status === "success" &&
          blogForm.blogSections &&
          blogForm.blogSections.map((blogSection) => (
            <BlogSectionCreate
              events={eventQuery.data}
              blogSection={blogSection}
              dispatch={blogFormDispatch}
              key={blogSection.id}
            />
          ))}
      </div>
      <div>
        <button
          type="submit"
          disabled={
            imageMutation.isLoading || blogForm.title === "" ? true : false
          }
          onClick={(e) => createBlog(e)}
          className={
            (imageMutation.isLoading ? "bg-indigo-300" : "bg-indigo-600") +
            " fixed bottom-0 border border-transparent h-20 shadow-sm w-full inline-flex justify-center items-center text-lg font-medium text-white hover:bg-indigo-700 focus:outline-none active:outline-none disabled:opacity-50"
          }
        >
          Create Blog
        </button>
      </div>
    </div>
  );
};

export default BlogCreate;
