import React from "react";
import austin from "modules/common/images/austin.png";
import matthew from "modules/common/images/matthew.png";
import tdawg from "modules/common/images/tdawg.png";
import AboutDevs from "modules/about/components/AboutDevs";

const About = () => {

    const devs = [
        {
            name: "Matthew",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doeiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doeiusmod tempor incididunt.",
            image: matthew,
        } ,
        {
            name: "Austin",
            description: "This is Austin",
            image: austin,
        } ,
        {
            name: "Trevor",
            description: "This is Trevor",
            image: tdawg,
        } ,
    ]

    return (
        <div className="Aboutus">
            <div className="grid container h-40 px-8 pt-10 m-auto">
                <h2 className="place-self-center font-medium text-2xl ">Meet our devs...</h2>
            </div>
            <div className="flex justify-center">
                <AboutDevs devs = {devs}/>
            </div>
        </div>
    );
};

export default About;