import React from "react";
import { Link } from "react-router-dom";

// Event component
const EventCard = ({ event }) => {
  const event_date = new Date(event.updatedAt);

  return (
    <div>
      <Link to={"/event/" + event._id}>
        <div
          className=" bg-cover bg-center w-full flex justify-end flex-col relative"
          style={{
            backgroundImage: ` url(
            "${event.image}"
          )`,
            animation: "fadein 1s",
            paddingBottom: "80%",
          }}
        ></div>
        <div className="mt-4">
          <h3 className="text-black text-lg leading-5">{event.title}</h3>
          <h3 className="text-gray-70 text-sm font-thin mt-1">
            {event_date.toDateString()}
          </h3>
        </div>
      </Link>
    </div>
  );
};

export default EventCard;
