import { TYPES } from "modules/common/icons";

export const eventSubTypeInfo = {
  meal: {
    subTypes: {
      breakfast: { pitTitles: ["breakfast"], icon: TYPES.EGG },
      lunch: { pitTitles: ["lunch"], icon: TYPES.BURGER },
      dinner: { pitTitles: ["dinner"], icon: TYPES.DINNER_TABLE },
      drinks: { pitTitles: ["drinks"], icon: TYPES.COCKTAIL },
      other: { pitTitles: ["other"], icon: TYPES.OTHER },
    },
    icon: TYPES.MEAL,
  },
  transit: {
    subTypes: {
      plane: { pitTitles: ["depart", "arrive"], icon: TYPES.PLANE },
      train: { pitTitles: ["depart", "arrive"], icon: TYPES.OTHER },
      car: { pitTitles: ["depart", "arrive"], icon: TYPES.CAR },
      bus: { pitTitles: ["depart", "arrive"], icon: TYPES.BUS },
      boat: { pitTitles: ["depart", "arrive"], icon: TYPES.BOAT },
      other: { pitTitles: ["depart", "arrive"], icon: TYPES.OTHER },
    },
    icon: TYPES.PLANE,
  },
  lodging: {
    subTypes: {
      hotel: { pitTitles: ["check in", "check out"], icon: TYPES.HOTEL_ROOM },
      airbnb: { pitTitles: ["check in", "check out"], icon: TYPES.AIRBNB },
      other: { pitTitles: ["check in", "check out"], icon: TYPES.OTHER },
    },
    icon: TYPES.BED,
  },
  other: {
    subTypes: {
      rental: { pitTitles: ["rental"], icon: TYPES.OTHER },
      souvenir: { pitTitles: ["souvenir"], icon: TYPES.OTHER },
      entertainment: { pitTitles: ["entertainment"], icon: TYPES.OTHER },
      other: { pitTitles: ["other"], icon: TYPES.OTHER },
    },
    icon: TYPES.OTHER,
  },
};
