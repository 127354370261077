import React, { useEffect, useCallback, useState } from "react";
import moment from "moment-timezone";
import Autocomplete from "react-google-autocomplete";
import geoTz from "geo-tz";
import dropdownTimezones from "../helpers/DropdownData";
import { ACTIONS } from "..";
import TextField from "modules/common/components/fields/TextField";
import Icon, { TYPES } from "modules/common/icons";

// Event component
const PointInTime = ({ dispatch, pointInTime, index, panels }) => {
  const eventCreateLocation = "eventCreateLocation" + pointInTime._id;
  const eventCreateDateTime = "eventCreateDateTime" + pointInTime._id;
  const eventCreateTZ = "eventCreateTZ" + pointInTime._id;
  const eventCreateDetails = "eventCreateDetails" + pointInTime._id;
  const [details, setDetails] = useState(pointInTime.details);
  const [canDelete, setCanDelete] = useState(panels > 1);

  // Sets a State for determining if the Delete button should be displayed on the point in times or not.
  useEffect(() => {
    if (panels <= 1) {
      return setCanDelete(false);
    }
    setCanDelete(true);
  }, [panels]);

  // function to update the points in time
  const updatePointInTime = useCallback(
    (attr, value) => {
      dispatch({
        type: ACTIONS.UPDATE_POINT_IN_TIME,
        payload: {
          id: pointInTime.id,
          index: index,
          attr: attr,
          value: value,
        },
      });
    },
    [dispatch, index, pointInTime.id]
  );

  // function for deleting a point in time.
  const deletePointInTime = () => {
    if (!canDelete) {
      return;
    }
    dispatch({
      type: ACTIONS.DELETE_POINT_IN_TIME,
      payload: {
        id: pointInTime._id,
        temp_id: pointInTime.temp_id,
      },
    });
  };

  // When getting a location, this function attempts to update the timezone based on location coordinates
  useEffect(() => {
    if (
      pointInTime.location &&
      pointInTime.location.latitude &&
      pointInTime.location.longitude
    )
      try {
        updatePointInTime(
          "timezone",
          geoTz(
            pointInTime.location.latitude,
            pointInTime.location.longitude
          )[0]
        );
      } catch (error) {
        console.log("No timezone found");
      }
  }, [pointInTime.location, updatePointInTime]);

  // useEffect for handling details updates
  useEffect(() => {
    updatePointInTime("details", details);
  }, [details, updatePointInTime]);

  return (
    <div className="relative grid grid-cols-4 -mx-2 px-2">
      {canDelete && (
        <button
          type="button"
          className="absolute right-0 top-0 focus:outline-none"
          onClick={(e) => {
            deletePointInTime();
          }}
        >
          <Icon
            icon={TYPES.DELETE}
            className="h-6 text-theme-red-300 opacity-60"
          />
        </button>
      )}
      {pointInTime.subTitle && (
        <h3 className="text-xl capitalize">{pointInTime.subTitle}</h3>
      )}

      <div className="col-span-4">
        <label
          htmlFor={eventCreateLocation}
          className="block  font-medium text-gray-700"
        >
          Location
        </label>

        <Autocomplete
          onPlaceSelected={(place) => {
            place["latitude"] = place.geometry.location.lat();
            place["longitude"] = place.geometry.location.lng();
            updatePointInTime("location", place);
          }}
          value={pointInTime.formattedAddress && pointInTime.formattedAddress}
          fields={[
            "address_component",
            "photos",
            "adr_address",
            "business_status",
            "formatted_address",
            "geometry",
            "icon",
            "name",
            "business_status",
            "photo",
            "place_id",
            "plus_code",
            "type",
            "url",
            "utc_offset_minutes",
            "vicinity",
          ]}
          types={["geocode", "establishment"]}
          type="text"
          onFocus={(e) => e.target.setAttribute("autocomplete", "off")}
          id={eventCreateLocation}
          name={eventCreateLocation}
          className="max-w-md mt-1 block w-full h-12 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-theme-purple-300 focus:border-theme-purple-300 sm:text-sm"
          // onChange={(e) => setGoogleAddress(e.target.value)}
        />
      </div>
      <TextField
        text={details}
        setText={setDetails}
        placeholder="Add any additional details"
        title="Details"
        uniqueKey={eventCreateDetails}
        className="col-span-4 mt-6"
      />
      <div className="col-span-4 mt-6">
        <label
          htmlFor={eventCreateDateTime}
          className="block  font-medium text-gray-700"
        >
          Date/Time
        </label>

        <input
          id={eventCreateDateTime}
          name={eventCreateDateTime}
          type="datetime-local"
          value={
            pointInTime.dateTime &&
            moment
              .tz(
                pointInTime.dateTime,
                "yyyy-MM-DD[T]HH:mm",
                pointInTime.timezone
              )
              .format("yyyy-MM-DD[T]HH:mm")
          }
          autoComplete="off"
          onChange={(e) => {
            updatePointInTime(
              "dateTime",
              moment
                .tz(e.target.value, "yyyy-MM-DD[T]HH:mm", pointInTime.timezone)
                .utc()
                .format()
            );
          }}
          className="max-w-md mt-1 h-12 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-theme-purple-300 focus:border-theme-purple-300 sm:text-sm"
        ></input>
      </div>
      <div className="col-span-2 mt-6">
        <label
          htmlFor={eventCreateTZ}
          className="block  font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Timezone
        </label>
        <div className="mt-1">
          <select
            id={eventCreateTZ}
            name={eventCreateTZ}
            value={pointInTime.timezone && pointInTime.timezone}
            onChange={(e) => {
              updatePointInTime("timezone", e.target.value);
            }}
            className="max-w-md h-12 block focus:ring-theme-purple-300 focus:border-theme-purple-300 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
          >
            <option value="">-- select --</option>
            {dropdownTimezones?.dropdownTimezones.map((tz) => (
              <option key={tz} id={tz}>
                {tz}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default PointInTime;
