import React, { useEffect } from "react";
import MDEditor from "@uiw/react-md-editor";
import { ACTIONS } from "..";

// Event component
const BlogSectionCreate = ({ dispatch, blogSection, events }) => {
  const blogSectionEvent = "blogSectionEvent" + blogSection.id;
  const blogSectionTitle = "blogSectionTitle" + blogSection.id;
  const blogSectionDescription = "blogSectionDescription" + blogSection.id;

  const updateBlogSection = (value, attr) => {
    dispatch({
      type: ACTIONS.UPDATE_BLOG_SECTION,
      payload: {
        id: blogSection.id,
        attr: attr,
        value: value,
      },
    });
  };

  useEffect(() => {
    if (blogSection.event)
      dispatch({
        type: ACTIONS.UPDATE_BLOG_SECTION,
        payload: {
          id: blogSection.id,
          attr: "image",
          value: events.filter((event) => event._id === blogSection.event)[0]
            .image,
        },
      });
  }, [blogSection.event, blogSection.id, dispatch, events]);

  return (
    <div>
      <div className="col-span-4 grid grid-cols-4 space-y-6 ">
        <div className="col-span-4">
          <img
            className="w-24"
            src={blogSection.event && blogSection.image}
            alt=""
          />
        </div>
        <div className="col-span-4">
          <label
            htmlFor={blogSectionEvent}
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Related Event
          </label>
          <div className="mt-1">
            <select
              defaultValue={"default"}
              id={blogSectionEvent}
              name={blogSectionEvent}
              onChange={(e) => {
                updateBlogSection(e.target.value, "event");
              }}
              className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
            >
              <option disabled value="default">
                -- select --
              </option>
              {events &&
                events.map((event) => (
                  <option value={event._id}>{event.title}</option>
                ))}
            </select>
          </div>
        </div>

        <div className="col-span-4">
          <label
            htmlFor={blogSectionTitle}
            className="block text-sm font-medium text-gray-700"
          >
            Title
          </label>
          <input
            id={blogSectionTitle}
            name={blogSectionTitle}
            value={blogSection.title ? blogSection.title : ""}
            autoComplete="off"
            onChange={(e) => updateBlogSection(e.target.value, "title")}
            className="max-w-md mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          ></input>
        </div>
        <div className="col-span-4">
          <label
            htmlFor={blogSectionDescription}
            className="block mb-1 text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Description
          </label>
          <MDEditor
            id={blogSectionDescription}
            name={blogSectionDescription}
            height={200}
            value={blogSection.description ? blogSection.description : ""}
            preview="edit"
            visiableDragbar={false}
            onChange={(value) => {
              updateBlogSection(value, "description");
            }}
            className="overflow-hidden"
          />
        </div>
      </div>
    </div>
  );
};

export default BlogSectionCreate;
