import React from "react";
import { useHistory } from "react-router-dom";
import lostKitten from "./images/kitty.svg";
import PagePadding from "modules/navbar/components/PagePadding";

const NotFoundPage = () => {
  const history = useHistory();

  return (
    <>
      <PagePadding />
      <div className="m-auto">
        <div className="container flex flex-col h-full align-middle md:flex-row items-center justify-center px-5 text-gray-700">
          <div className="max-w-md">
            <div className="text-5xl font-dark font-bold">404</div>
            <p className="text-2xl md:text-3xl font-light leading-normal pb-2">
              Sorry we couldn't find this page.
            </p>
            <button
              onClick={() => {
                history.push("/");
              }}
              className="px-4 inline py-2 text-sm font-medium leading-5 shadow text-white transition-colors duration-150 border border-transparent rounded-lg focus:outline-none bg-theme-red-300 hover:bg-theme-red-400"
            >
              back to homepage
            </button>
          </div>
          <div className="max-w-lg md:pt-0 pt-12">
            <img
              className="h-52 mr-auto ml-auto"
              src={lostKitten}
              alt="Workflow"
            />
            <div>
              Icons made by{" "}
              <a href="https://www.freepik.com" title="Freepik">
                Freepik{" "}
              </a>
              from{" "}
              <a href="https://www.flaticon.com/" title="Flaticon">
                www.flaticon.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
