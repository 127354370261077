import React from "react";

// Event component
const Footer = () => {
  return (
    <div className="my-18 container mx-auto px-8 pt-20 lg:px-52 xl:px-72 text-center">
      <p className="mb-6">
        Please note that some of the links on our site are affiliate links. If
        you choose to purchase through these links, we may receive a small
        commission at no extra cost to you. By using these affiliate links you
        are directly supporting TravelBudgy to stay on the road, and to provide
        you with free content to help you travel more, and travel better.
      </p>
      <p className="mb-6">
        © COPYRIGHT TRAVELBUDGY LTD 2021 ALL RIGHTS RESERVED
      </p>
      <p className="pb-6">PHOTOGRAPHY CANNOT BE USED WITHOUT OUR PERMISSION</p>
    </div>
  );
};

export default Footer;
