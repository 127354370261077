import React from "react";
import Reveal from "react-reveal/Reveal";

const TextSubSection = ({ title, description, className }) => {
  return (
    <div className={className}>
      <Reveal effect="fadeIn">
        <h2 className="font-medium text-2xl mb-8">{title}</h2>
      </Reveal>

      <p className="font-light text-md leading-tight text-gray-900 mb-20">
        {description}
      </p>
    </div>
  );
};

export default TextSubSection;
