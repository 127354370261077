import React from "react";
import dateFormat from "dateformat";
import { defaultImages } from "modules/common/images/CommonImageLinks";

const TripCard = ({
  tripTitle,
  image,
  startDate,
  endDate,
  attendeesImages,
}) => {
  return (
    <div className="h-72 sm:h-80 lg:h-96 col-span-1 rounded-lg shadow-md">
      <img
        src={image || defaultImages.defaultTripImageUrl}
        alt="..."
        className="h-3/5 rounded-t-lg w-full object-cover"
      />
      <div className="text-3xl font-extralight lg:mt-2 ml-2">{tripTitle}</div>
      <div className="text-md font-extralight ml-2 mt-0 lg:mt-2">
        {dateFormat(startDate, "longDate") +
          " - " +
          dateFormat(endDate, "longDate")}
      </div>
      <div className="flex flex-row">
        {attendeesImages.map((attendee) => {
          return (
            <img
              key={attendee + "-attendee_image"}
              src="https://icatcare.org/app/uploads/2019/09/The-Kitten-Checklist-1-1200x630.png"
              alt="..."
              className="shadow rounded-full h-10 w-10 ml-2 mt-2 lg:mt-4"
            />
          );
        })}
      </div>
    </div>
  );
};

export default TripCard;
