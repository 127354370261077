import React, { useContext } from "react";
import "styles/Images.css";
import hero_image_mobile from "./images/Machu75-mobile.webp";
import hero_image_desktop from "./images/Machu75.jpg";
import contact_image from "./images/trolly-photo-mobile.webp";
import { Footer } from "modules/common/components";
import UserContext from "context/UserContext";
import { Link, useParams } from "react-router-dom";
import EventGallery from "modules/userhome/components/EventGallery";
import BlogGallery from "modules/userhome/components/BlogGallery";
import TextSubSection from "modules/userhome/components/TextSubSection";
import HeroImageTitle from "modules/userhome/components/HeroImageTitle";
import AddItemButton from "modules/additembutton";

// Home component
const UserHome = () => {
  // get param from browser url
  const { urlDisplayName } = useParams();

  // get context for user
  const { user } = useContext(UserContext);

  const isAdmin = () => {
    return urlDisplayName?.toLowerCase() === user?.displayName.toLowerCase();
  };

  const admin = isAdmin();

  // set image
  const heroImage =
    window.innerWidth >= 800 ? hero_image_desktop : hero_image_mobile;

  return (
    <div className="w-full">
      <AddItemButton />
      <HeroImageTitle
        image={heroImage}
        title={urlDisplayName}
        subTitle="Adventures and Travel"
      />
      <div className=" container  mx-auto px-8 py-20 sm:px-8 xl:px-72">
        <TextSubSection
          title="Our Goal"
          description=" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Morbi
          blandit cursus risus at ultrices mi tempus. Eget est lorem ipsum dolor
          sit. Aliquet bibendum enim facilisis gravida. Lacus sed turpis
          tincidunt id aliquet. Porttitor leo a diam sollicitudin tempor. Varius
          duis at consectetur lorem donec massa sapien faucibus. Tortor pretium
          viverra suspendisse potenti nullam ac tortor. Adipiscing elit
          pellentesque habitant morbi tristique senectus et netus. Duis at
          tellus at urna. Aliquam purus sit amet luctus venenatis."
        />
      </div>

      <div className="lg:container lg:mx-auto xl:px-72">
        {/* This can be made into a component as well once we think on it a little */}
        <div
          style={{
            backgroundImage: ` linear-gradient(to bottom, rgba(256, 256, 256, 0.7), rgba(256, 256, 256, 0.7)), url(${contact_image})`,
          }}
          className="h-80 mx-auto bg-cover bg-center container px-8 py-14 sm:px-8 lg:px-20 flex justify-center items-center"
        >
          <div>
            <div className="flex justify-center items-center flex-col border-t-2 border-b-2 border-gray-600 p-6">
              <h3 className="text-3xl font-light mb-5">Follow Us</h3>
              <div className="flex justify-between w-40 font-light">
                <span>Link 1</span>
                <span>Link 2</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" container mx-auto px-8 pt-20 sm:px-8 xl:px-72">
        <TextSubSection
          title="Blog Posts"
          description="Tortor pretium viverra suspendisse potenti nullam ac tortor.
          Adipiscing elit pellentesque habitant morbi tristique senectus et
          netus. Duis at tellus at urna. Aliquam purus sit amet luctus
          venenatis."
        />

        {admin && (
          <div className="flex justify-end">
            <Link
              to="/blog/create"
              className="bg-green-200 px-2 py-1 rounded-2xl inline-block mb-4"
            >
              Create Blog
            </Link>
          </div>
        )}
        <BlogGallery displayName={urlDisplayName} />

        <TextSubSection
          className="mt-12"
          title="Adventure Logs"
          description="Tortor pretium viverra suspendisse potenti nullam ac tortor.
          Adipiscing elit pellentesque habitant morbi tristique senectus et
          netus. Duis at tellus at urna. Aliquam purus sit amet luctus
          venenatis."
        />

        {admin && (
          <div className="flex justify-end">
            <Link
              to="/event/create"
              className="bg-green-200 px-2 py-1 rounded-2xl inline-block mb-4"
            >
              Create Event
            </Link>
          </div>
        )}
        <EventGallery displayName={urlDisplayName} />
      </div>

      <Footer />
    </div>
  );
};

export default UserHome;
