import React from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import "index.css";

const DateRangePicker = ({
  from,
  to,
  setFrom,
  setTo,
  numberOfMonths,
  fixedWeeks,
  displayAsterick,
  title,
}) => {
  const modifiers = { start: from, end: to };

  function handleDayClick(day) {
    const range = DateUtils.addDayToRange(day, { from, to });
    setFrom(range.from);
    setTo(range.to);
  }

  return (
    <div className="">
      {displayAsterick && (
        <div className="inline text-theme-red-400 font-medium mg:text-lg">
          {"* "}
        </div>
      )}
      {title && (
        <label className="inline font-medium text-gray-700 md:text-lg">
          {title}
        </label>
      )}
      <DayPicker
        className="Selectable w-full flex justify-start"
        numberOfMonths={numberOfMonths}
        selectedDays={{ from, to }}
        modifiers={modifiers}
        onDayClick={handleDayClick}
        fixedWeeks={fixedWeeks}
      />
    </div>
  );
};

DateRangePicker.defaultProps = {
  numberOfMonths: 1,
  fixedWeeks: false,
  displayAsterick: false,
};

export default DateRangePicker;
