import React from "react";
import LazyLoad from "react-lazyload";
import { useQuery } from "react-query";
import { getBlogs } from "api/Queries";
import BlogCard from "./BlogCard";

const BlogGallery = ({ displayName }) => {
  const blogQuery = useQuery(displayName + "blogs", () =>
    getBlogs(displayName)
  );
  return (
    <div className="gap-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 lg:gap-6">
      {blogQuery.status === "success" &&
        blogQuery.data.map((blog) => (
          <div key={blog._id} className="col-span-1">
            <LazyLoad offset={500}>
              <BlogCard blog={blog} maxLines={3} />
            </LazyLoad>
          </div>
        ))}
    </div>
  );
};

export default BlogGallery;
